const KPI_Thresholds = {
    WORK_RATE: ['85.00', '95.00', '105.00', '115.00', '120.00'],
    SPEED: ['17.00', '20.00', '23.00', '25.50', '27.50', '29.00', '30.50', '31.50'],
    ACCELERATION: ['2.00', '2.50', '3.00', '3.50', '4.00', '6.00'],
    DECELERATION: ['-2.00', '-2.50', '-3.25', '-4.00', '-4.75', '-5.50'],
};

/**
 * Generates an object mapping KPI_Thresholds to their corresponding keys for each metric type
 * @param {Object} thresholds - object where keys are metric types (e.g WORK_RATE, SPEED) and values are arrays of thresholds
 * @returns {Object} - object where each metric type maps to another object then that inner object maps each threshold to a generated key
 * Example Output:
 * {
 *   WORK_RATE: {
 *     '85.00': 'WORK_RATE85.00',
 *     '95.00': 'WORK_RATE95.00',
 *     ...
 *   },
 *   SPEED: {
 *     '17.00': 'SPEED17.00',
 *     '20.00': 'SPEED20.00',
 *     ...
 *   }
 * }
 */

const generateKeys = thresholds =>
	Object.entries(thresholds).reduce((acc, [metricType, values]) => {
		acc[metricType] = values.reduce((keyAcc, threshold) => {
			const keyName = `${metricType}${threshold}`;
			keyAcc[threshold] = keyName;
			return keyAcc;
		}, {});
		return acc;
	}, {});

const GeneratedKeys = generateKeys(KPI_Thresholds);

export { KPI_Thresholds, GeneratedKeys };
