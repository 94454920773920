<template>
    <div>
        <button class="export-button button button-export px-0" style="color:var(--sportlight-teal);"
            @click="download()">
            <span class="button-text">
                <svg class="download" id="Layer_0" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 16 16">
                    <path class="cls-1"
                        d="M7.76,12.57a.33.33,0,0,0,.47,0L12.9,7.9a.33.33,0,0,0-.24-.57h-2v-7A.33.33,0,0,0,10.33,0H5.67a.33.33,0,0,0-.33.33v7h-2a.33.33,0,0,0-.24.57Z">
                    </path>
                    <path class="cls-1"
                        d="M15.33,11.67a.67.67,0,0,0-.67.67v2.33H1.33V12.33a.67.67,0,1,0-1.33,0v3A.67.67,0,0,0,.67,16H15.33a.67.67,0,0,0,.67-.67v-3A.67.67,0,0,0,15.33,11.67Z">
                    </path>
                </svg>
                {{title}}
            </span>
        </button>
    </div>
</template>

<script>
import { UserData } from "@/components/UserData";
import { errorHandler } from "@/components/ErrorHandler";
import download from "@/utils/Download";

export default {
    props: {
        session: Object,
        title: String,
        api: String,
        filePrefix: String,
        fileType: String
    },
    data() {
        return {
        customerId: UserData.customerId()
    };
},
    methods: {
        async download() {
            await this.$root.executeTaskWithProgressBar(async () => {
                const response = await this.$root.webApiGet(`/${this.api}?customerId=${this.customerId}&sessionId=${this.session.sessionId}`);
                if (response.status === 200) {
                    const base64data = response.data.base64EncodedString;
                    const blob = new Blob([Uint8Array.from(atob(base64data), c => c.charCodeAt(0))], { type: `application/${this.fileType};base64` });
                    const convertedDate = this.session.date.replace(/-/g, ""); //replace - with _
                    const title = this.session.title.replace(/ /g, "_"); //replace white spaces with _
                    const file = `${this.filePrefix}_${title}_${convertedDate}.${this.fileType}`;
                    download(blob, file);
                } else {
                    errorHandler.error(response, this);
                }
            });
        }
    },
};
</script>

<style>

</style>