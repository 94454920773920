<template>
    <div class="section-container w-100">
        <SectionTitle :title="'Peak Threshold Counts'">
            <MoreInfo :id="'peak-threshold-effort-counts-more-info'" :title="'Peak Threshold Counts'">
                <p>
                    Peak Threshold Counts shows the player’s total number of efforts completed above a
                    set absolute peak threshold,
                    for three epoch lengths. The coloured line represents the current session,
                    while the box and whisker plot represents the distribution of previous
                    competitive matches with at least 85 minutes played.
                    <br>
                    Thresholds are set to determine a peak period within each epoch,
                    derived from league-wide representative data.
                </p>
            </MoreInfo>
        </SectionTitle>
        <div v-if="hasData">
            <PeakThresholdCountRow />
            <PeakThresholdCountRow :counts="distanceCounts" label="Distance" />
            <PeakThresholdCountRow :counts="hsrCounts" label="HSR" />
            <PeakThresholdCountRow :counts="sprintCounts" label="Sprints" />
            <PeakThresholdCountRow :counts="turnCounts" label="Turns" />
        </div>
        <MissingDataText v-else :message="'Peak Threshold Counts Unavailable'" />
    </div>
</template>

<script>
import MoreInfo from "@/components/MoreInfo.vue";
import MissingDataText from "@//components/MissingDataText.vue";
import SectionTitle from "@//components/SectionTitle.vue";
import PeakThresholdCountRow from "../PeakThresholdCountRow.vue";
export default {
    props: {
        data: Object
    },
    components: {
        PeakThresholdCountRow,
        MoreInfo,
        SectionTitle,
        MissingDataText
    },
    data () {
        return {
            distanceCounts: null,
            hsrCounts: null,
            sprintCounts: null,
            turnCounts: null,
            hasData: false,
        };
    },
    methods: {
        getPeakThresholdCount() {
            this.hasData = this.data.kpiEpochCounts.DISTANCE !== undefined;
            if (this.hasData) {
                const { DISTANCE, HSR, SPRINT, TURNS } = this.data.kpiEpochCounts;
                this.distanceCounts = DISTANCE;
                this.hsrCounts = HSR;
                this.sprintCounts = SPRINT;
                this.turnCounts = TURNS;
            }
        }
    },
    async mounted() {
        this.getPeakThresholdCount();
    },
    watch: {
        data() {
            this.getPeakThresholdCount();
        }
    }
};
</script>

<style>
</style>