<template>
    <div class="table-container" :id=id>
        <table>
            <thead>
                <tr>
                    <td class="blank-cell"></td>
                    <td v-for="i in data" :key="i[columnKey]" style="max-height:15vh">
                        <span class="rotated-table-heading">
                            <template v-if="!isPlayerTable">{{ i.sessionDate }} <br> </template>
                            {{ i[headingKey] }}
                        </span>
                    </td>
                </tr>
                <template v-if="isPlayerTable">
                    <tr>
                        <td class="blank-cell"></td>
                        <td v-for="player in data" :key="player[columnKey]">
                            <span v-if="player.shirtNumber">#{{ player.shirtNumber }}</span>
                            <span v-else>-</span>
                        </td>
                    </tr>
                    <tr>
                        <td class="blank-cell"></td>
                        <td v-for="player in data" :key="player[columnKey]">
                            {{ player.position }}
                        </td>
                    </tr>
                </template>
            </thead>
            <tbody v-for="kpiGroup in kpiGroups" :key="kpiGroup.title">
                <tr class="kpi-group-title-row">
                    <td class="kpi-name-column">{{ kpiGroup.title }}</td>
                </tr>
                <tr v-for="metric in kpiGroup.metrics" :key="metric.label">
                    <!-- Hides the wellness kpis on opposition table for oppositions without permission and always display non-wellness rows-->
                    <template v-if="!isOpposition || displayWellnessDataForOpposition || !wellnessKeys.includes(metric.key)">
                        <td v-if="isWip" class="kpi-name-column" :title="metric.info">{{ metric.label }}</td>
                        <td v-else class="kpi-name-column">{{ metric.label }}</td>
                        <td v-for="i in data" :key="i[columnKey]" class="metric-value-cell">
                            <MetricValue :value="i[metric.key]" :metric="metric.key" />
                        </td>
                    </template>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
import MetricValue from './MetricValue.vue';
import { UserData } from "@/components/UserData";

export default {
    data() {
        return {
            displayWellnessDataForOpposition: UserData.displayWellnessDataForOpposition(),
            wellnessKeys: ['hamstringRisk', 'fatigueFlag'],
            isWip: UserData.isWIP()
        };
    },
    components: {
        MetricValue,
    },
    props: {
        id: String,
        data: Array,
        kpiGroups: Array,
        columnKey: String,
        isPlayerTable: Boolean,
        headingKey: String,
        isOpposition: Boolean,
    },
};
</script>

<style scoped>
.table-container {
    overflow-x: auto;
    height: 68vh; 
}

table {
    border-collapse: collapse;
    font-size: small;
}

thead {
    text-align: center;
    background-color: var(--bg-primary);
    padding: 2px;
    position: sticky;
    margin:auto;
    top: 0;
    z-index: 2;
}

.rotated-table-heading {
    writing-mode:vertical-rl; 
    transform: rotate(180deg); 
}

thead td {
    position: relative;
}

thead td:not(:first-child)::before {
    content: "";
    position: absolute;
    margin: auto;
    top: 0;
    left: 0;
    bottom: 0;
    width: 1.2px;
    background-color: var(--input-box-bg);
}

tbody th, tbody td {
    border-top: 2px solid var(--bg-primary);
    background-color: var(--input-box-bg);
    border-right: 1.5px solid var(--bg-primary);
    min-width: 3.5vw;
}

.blank-cell,
.kpi-name-column {
    min-width: 15vw;
    width: 15vw;
    position: sticky;
    left: 0;
    background-color: var(--input-box-bg);
    z-index: 1;
}

.blank-cell,
.kpi-group-title-row td {
    background-color: var(--bg-primary);
}

.metric-value-cell {
    text-align: center;
    padding: 0.1em 0em;
    min-width: 4vw;
}
</style>