<template>
    <div :style="{ 'min-height': height + 'vh' }"  class="overflow-auto mt-2">
        <div style="min-width: max-content;">
            <div class="row mx-auto d-flex justify-content-center align-items-center spo">
                <h6 class="col-2 fixed-squad-column column-heading text-left">{{ positionLabel }}</h6>
                <span class="col-10 d-flex">
                    <h6 class="column-heading">Total Distance (<MeasurementUnit :type="LongDistance" />)</h6>
                    <h6 class="column-heading">High Speed Distance (<MeasurementUnit :type="ShortDistance" />)</h6>
                    <h6 class="column-heading">Sprint Distance (<MeasurementUnit :type="ShortDistance" />)</h6>
                    <h6 class="column-heading">Turns (N)</h6> 
                    <h6 class="column-heading">Accelerations &gt; <MeasurementValue :type="Acceleration" :value=3 :format="formatCoarse"/><MeasurementUnit :type="Acceleration" /> (N)</h6>
                    <h6 class="column-heading">Decelerations &gt; <MeasurementValue :type="Acceleration" :value=3 :format="formatCoarse"/><MeasurementUnit :type="Acceleration" /> (N)</h6>
                </span>
            </div>
            <hr>
            <div :id="id">
                <div v-for="(playerData, index) in data" :key="index">
                    <MissingDataText :message="positionLabel + 's Data Unavailable'"
                        v-if="metrics.every(metric => playerData[metric].comparisonData === null)" />
                    <div class="row mx-auto d-flex justify-content-center align-items-center" v-else>
                        <span class="col-2 d-flex align-items-center rainbow-row-container fixed-squad-column">{{ playerData.player.name }}</span>
                        <div class="col-10 d-flex">
                            <div class="rainbow-row-container  d-flex align-items-center " v-for="metric in metrics" :key="metric">
                                <RainbowBarChart class="rainbow-bar-charts" :id="metric + playerData.player.playerId"
                                    :data="playerData[metric]" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import RainbowBarChart from "../components/RainbowBarChart.vue";
import MissingDataText from "../components/MissingDataText.vue";
import MeasurementUnit from "../components/MeasurementUnit.vue";
import MeasurementValue from "../components/MeasurementValue.vue";
import { MeasurementTypes, MeasurementUnit as MU } from "../utils/MeasurementSystem";

export default {
    components: {
        RainbowBarChart,
        MissingDataText,
        MeasurementUnit,
        MeasurementValue 
    },
    props: {
        positionLabel: String,
        data: Array,
        id: String
    },
    data() {
        return {
            Acceleration: MeasurementTypes.Acceleration,
            ShortDistance: MeasurementTypes.ShortDistance,
            LongDistance: MeasurementTypes.LongDistance, 
            formatCoarse: MU.prototype.formatCoarse,
            height: (this.data.length + 0.8) * 7,  //Add 0.8 to the number of players in each position for padding then x 7 to get vertical height
            metrics: ['distanceData', 'hsdData', 'sprintDistanceData', 'turnsData', 'accelerationsData', 'decelerationsData'],
        };
    },
};
</script>

<style>
.column-heading {
    height: 4vh;
    width: 12vw;
    margin: auto 0;
    text-align: center;
}

.rainbow-row-container {
    width: 12vw;
    height: 7vh;
}
.rainbow-bar-charts {
    padding: 0 0.1em;
}

.fixed-squad-column {
    position: sticky;
    left: 0;
    z-index: 1;
    background-color: var(--bg-primary);
    font-size: 12px;
    font-weight: 400;
}

hr {
    border: none;
    border-top: 1px solid #5a5e6280;
    margin: 0.7em 0;
}

@media (max-width: 992px) {
.rainbow-row-container, .column-heading {
    min-width: 25vw;
}
}
</style>