<template>
    <div class="section-container w-100">
        <SectionTitle title="Significant Turn Summary">
            <MoreInfo :id="'turn-info-modal'" :title="'Significant Turn Summary Description'">
                <SignificantTurnSummaryInfoText :includeEntrySpeed="true" :turnEntrySpeedBoundaries="turnEntrySpeedBoundaries"/>
            </MoreInfo>
        </SectionTitle>
        <MissingDataText v-if="!data" :message="'Significant Turn Summary Unavailable'" />
        <div class="row mx-auto p-4">
            <div class=" col-12 col-lg-6 mt-2" v-if="angleData">
                <TurnRatesPlot id="angle-turn-rates" :data="angleData" title="Angle" :width="width" :height="height"
                    :viewport="viewPort" />
                <TurnRatesLegend type="angle" />
            </div>
            <div class="col-12 col-lg-6 mt-2" v-if="decelerationsData">
                <TurnRatesPlot id="decelerations-turn-rates" :data="decelerationsData" title="Peak Deceleration"
                    :width="width" :height="height" :viewport="viewPort" />
                <TurnRatesLegend type="deceleration" />
            </div>
            <div class="col-12 col-lg-6 mt-2" v-if="accelerationData">
                <TurnRatesPlot id="acceleration-turn-rates" :data="accelerationData" title="Peak Acceleration"
                    :width="width" :height="height" :viewport="viewPort" />
                <TurnRatesLegend type="acceleration" />
            </div>
            <div class="col-12 col-lg-6 mt-2" v-if="entrySpeedData">
                <TurnRatesPlot id="entry-speed-turn-rates" :data="entrySpeedData" title="Entry Speed" :width="width"
                    :height="height" :viewport="viewPort" />
                <TurnEntrySpeedsLegend :turnEntrySpeedBoundaries="this.turnEntrySpeedBoundaries"
                    :legendColours="entrySpeedColours" />
            </div>
        </div>
    </div>
</template>

<script>
import SectionTitle from "@/components/SectionTitle.vue";
import TurnRatesPlot from "@/components/TurnRatesPlot.vue";
import TurnRatesLegend from "@/components/TurnRatesLegend.vue";
import TurnEntrySpeedsLegend from "@/components/TurnEntrySpeedsLegend.vue";
import MoreInfo from "@/components/MoreInfo";
import MissingDataText from "@/components/MissingDataText";
import { getTurnRatesPercentages, getTurnRatesRanges, getTurnRatesLabels } from "@/utils/TurnRateHelpers";
import Colours from "@/utils/Colours";

export default {
    props: {
        data: Object,
        turnEntrySpeedBoundaries: Object
    },
    components: {
        SectionTitle,
        TurnRatesPlot,
        TurnRatesLegend,
        TurnEntrySpeedsLegend,
        MoreInfo,
        MissingDataText
    },
    data() {
        return {
            angleData: null,
            decelerationsData: null,
            accelerationData: null,
            entrySpeedData: null,
            width: '42vw',
            height: '25vh',
            viewPort: 32,
            entrySpeedColours: Colours.TURN_RATES.byEntrySpeed
        };
    },
    methods: {
        getTurnRates() {
            if (this.data) {
                const turnsByAngleClass = this.data.byAngleClass;
                const turnsByDecelerationsClass = this.data.byDecelerationPeakClass;
                const turnsByAccelerationClass = this.data.byAccelerationPeakClass;
                const turnsByEntrySpeed = this.data.byEntrySpeed;

                const turnRatesPercentages = getTurnRatesPercentages([turnsByAngleClass]);
                const turnRatesRanges = getTurnRatesRanges([turnsByAngleClass], null, [false]);
                const turnRatesLabels = getTurnRatesLabels([''], turnRatesRanges.leftTurnNormalRange, false, false);
                
                const classificationNames = ['Low', 'Medium', 'High'];
                const sharedData = { ...turnRatesRanges, ...turnRatesPercentages, 
                    labels: turnRatesLabels, type: 'DEFAULT', tooltipTitles: [''] };
                this.angleData = { turns: [turnsByAngleClass], classificationNames,
                    colours: Colours.TURN_RATES.byAngleClass, ...sharedData };
                this.decelerationsData = { turns: [turnsByDecelerationsClass], classificationNames,
                    colours: Colours.TURN_RATES.byDecelerationPeakClass, ...sharedData };
                this.accelerationData = { turns: [turnsByAccelerationClass], classificationNames,
                    colours: Colours.TURN_RATES.byAccelerationPeakClass, ...sharedData };
                this.entrySpeedData = { turns: [turnsByEntrySpeed], classificationNames: this.turnEntrySpeedBoundaries.names,
                    colours: Colours.TURN_RATES.byEntrySpeed, ...sharedData };

            }
        }
    },
    async mounted() {
        this.getTurnRates();
    },
    watch: {
        data() {
            this.getTurnRates();
        }
    }
};
</script>