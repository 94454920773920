import { GeneratedKeys, KPI_Thresholds } from './ReportingKpiThresholdKeyGenerator';
import { MeasurementUnit } from "@/utils/MeasurementSystem";

const formatThreshold = threshold => {
	const num = parseFloat(threshold);
	if (num % 1 === 0) {
		// Check if the threshold is a whole number (ends in .00)
		return parseInt(num); // Remove .00 by converting to an integer
	} else if (num.toString().endsWith(".0")) {
		// Check if the threshold ends with .0
		return num.toFixed(1).replace(".0", ""); // Remove trailing .0
	} else {
		// For other decimal values, leave as is
		return num;
	}
};

export default [
	{
		title: '',
		metrics: [
			{ label: 'Match Time (mins)', key: 'matchTime', info: 'Reported time that a player spent on the pitch during a match' },
			{ label: 'Work Rate', key: 'workRate', info: 'Quantifies a player’s workload relative to their match time and standard work rate of English Premier League players' },
			{ label: 'Fatigue Flag', key: 'fatigueFlag', info: 'Examines a player’s risk of fatigue following this match (Green = Low risk, Amber = Moderate risk, Red = High risk)' },
			{ label: 'Hamstring Risk', key: 'hamstringRisk', info: 'Examines how likely a player is to pick up a hamstring injury compared to the average player at any given time following this match (Green = Lower risk, Red = Higher risk)' },
		],
	},
	{
		title: 'Work Rate Time (mm:ss)',
		metrics: KPI_Thresholds.WORK_RATE.map((threshold) => ({
			label: `Time > ${formatThreshold(threshold)}WR`, // example output: "Time > 85WR"
			key: GeneratedKeys.WORK_RATE[threshold], // example output: "WORK_RATE85.00",
			csvColumnHeading: `Work Rate Time > ${formatThreshold(threshold)}WR (mm:ss)`, //example output: Work Rate Time > 85WR (mm:ss)
			info: `Time spent when work rate was over a value of ${formatThreshold(threshold)}`
		})),
	},
	{
		title: 'Speed Distances (m)',
		metrics: [{ label: 'Total Distance', key: 'totalDistance', csvColumnHeading: 'Total Distance (m)', info: 'Overall volume of distance covered during a session' },
			...KPI_Thresholds.SPEED.map((threshold) => ({
			label: `Distance > ${formatThreshold(threshold)} km/h`,
			key: GeneratedKeys.SPEED[threshold],
			csvColumnHeading: `Speed Distance > ${formatThreshold(threshold)} km/h (m)`,
			info: `Distance covered over a speed threshold of ${formatThreshold(threshold)} km/h (${MeasurementUnit.KilometresPerHour.reverseConvert(threshold).toFixed(1)} m/s)`
		}))],
	},
	{
		title: 'Acceleration Distances (m)',
		metrics: KPI_Thresholds.ACCELERATION.map((threshold) => ({
			label: `Distance > ${formatThreshold(threshold)} m/s²`,
			key: GeneratedKeys.ACCELERATION[threshold],
			csvColumnHeading: `Acceleration Distance > ${formatThreshold(threshold)} m/s² (m)`,
			info: `Distance covered over an acceleration threshold of ${formatThreshold(threshold)} m/s²`
		})),
	},
	{
		title: 'Deceleration Distances (m)',
		metrics: KPI_Thresholds.DECELERATION.map((threshold) => ({
			label: `Distance < ${formatThreshold(threshold)} m/s²`,
			key: GeneratedKeys.DECELERATION[threshold], 
			csvColumnHeading: `Deceleration Distance < ${formatThreshold(threshold)} m/s² (m)`,
			info: `Distance covered over a deceleration threshold of ${formatThreshold(threshold)} m/s²`
		})),
	},
];