import download from './Download';
import DateUtils from './DateUtils';
import kpiGroups from './ReportingKpiGroups';

class CSVGenerator {
	constructor({
		tableData,
		selectedDataType,
		fileName,
		isPlayer = false,
		sessionDate = '',
		sessionTitle = '',
	}) {
		this.tableData = tableData;
		this.selectedDataType = selectedDataType;
		this.fileName = fileName;
		this.isPlayer = isPlayer;
		this.sessionDate = sessionDate;
		this.sessionTitle = sessionTitle;
		this.excludedMetrics = ['fatigueFlag', 'hamstringRisk'];
		this.playerKeys = this.isPlayer ? ['playerName', 'position'] : ['name', 'position'];
	}

	// Generate headers dynamically
	generateHeaders() {
		const playerHeaders = ['Player Name', 'Position'];
		const sessionDetailsHeaders = ['Session Date', 'Title'];
		const metricHeaders = kpiGroups.flatMap(section =>
			section.metrics.flatMap(metric => {
				if (this.excludedMetrics.includes(metric.key)) return [];
				return metric.csvColumnHeading || metric.label;
			})
		);
		return [...playerHeaders, ...sessionDetailsHeaders, ...metricHeaders];
	}

	// Format session details depending on screen user is on
	formatSessionDetails(player) {
		if (this.isPlayer) {
			return [DateUtils.getOrdinalDate(player.sessionDate) || '', player.title || ''];
		}
		return [DateUtils.getOrdinalDate(this.sessionDate) || '', this.sessionTitle || ''];
	}

	// Generate metric data for a single player
	generateMetricData(player) {
		return kpiGroups.flatMap(section =>
			section.metrics.flatMap(metric => {
				if (this.excludedMetrics.includes(metric.key)) return [];
				const data = player[metric.key];
				if (data && typeof data === 'object') {
					const value = data.value !== undefined ? data.value : '';
					const percentile =
						data.percentile !== undefined ? data.percentile : '';
					return this.selectedDataType === 'data' ? value : percentile;
				}
				return [data !== undefined && data !== null ? data : ''];
			})
		);
	}

	// Generate rows dynamically
	generateRows() {
		return this.tableData.map(player => {
			const sessionDetails = this.formatSessionDetails(player);
			const playerData = this.playerKeys.map(key => player[key] || '');
			const metricData = this.generateMetricData(player);
			return [...playerData, ...sessionDetails, ...metricData];
		});
	}

	// Generate the final CSV content
	generateCSVContent() {
		const headers = this.generateHeaders();
		const rows = this.generateRows();
		return [
			headers.join(','), // CSV headers
			...rows.map((row) => row.map(value => `${value}`).join(',')), // CSV rows
		].join('\n');
	}

	// Trigger download for the CSV file
    downloadCSV() {
        // \uFEFF adds UTF-8 BOM to ensure proper encoding of special characters in applications like Excel and preserves formatting.
		const csvContent = `\uFEFF${this.generateCSVContent()}`;
		const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
		download(blob, this.fileName);
	}
}

export default function generateCSV(
	tableData,
	selectedDataType,
	fileName,
	isPlayer = false,
	sessionDate = '',
	sessionTitle = ''
) {
	const csvGenerator = new CSVGenerator({
		tableData,
		selectedDataType,
		fileName,
		isPlayer,
		sessionDate,
		sessionTitle,
	});
	csvGenerator.downloadCSV();
}