<template>
    <div :style="{opacity: !$root.isLoadingData ? 1 : 0}" id="content-wrap" style="margin-left:90px; width:100%">
        <WellnessHeader/>
        <MissingDataText v-if="selectedPlayersLength === 0" :message="'Selected squad is empty'"/>
        <div id="content" class="pt-4 pb-4 pl-3 pr-3" v-else>
            <div style="position: relative; height: auto; margin-left:15px;">
                <div>
                    <label class="switch">
                        <input type="checkbox" @click="togglePlots()" :checked=fixtureViewSelected />
                        <span class="slider round"></span>
                        <span class="switch-label">Player/Fixture View</span>
                    </label>
                </div>

                <div class="mt-4">
                    <div class="row">
                        <div class="col-4 col-lg-2">
                            <h4>Current Flags</h4>
                            <i v-if="currentlyFlaggedPlayers.length === 0"> No flagged players</i>
                            <ul v-for="(flag, key) in currentlyFlaggedPlayers " :key="key">
                                <li :style="{ color: flag.colour }" class="metric-tooltips">
                                    {{ flag.name }}
                                    <span class="metric-definition" style="width: 7vw; right: -70px;"> End
                                        date: {{ flag.endDate }} </span>
                                </li>
                            </ul>
                        </div>
                        <div class="col-4 col-lg-2">
                            <h4>Upcoming Fixture Flags</h4>
                            <i v-if="upcomingFixtureFatigueIsEmpty"> No flagged players</i>
                            <ul v-for="(flag, key) in upcomingFixturesFlaggedPlayers " :key="key">
                                <li :style="{ color: flag.colour }">{{ flag.name }}</li>
                            </ul>
                        </div>
                    </div>
                    <div v-if="displayInsights" class="fatigue-insights">
                        <h4>Fatigue Flag Insights (<i style="color:var(--sportlight-teal); font-size: small;">Previously observed insights for players competing in a fixture while red flagged</i>) </h4> 
                            <ul v-for="(f, key) in fatigueInsightList" :key="key">
                                <li>{{ 'EPL ' + f.positionGroupText + ' : ' + f.displayText}} </li>
                            </ul>
                    </div>

                </div>
                <div id="fatigue-plot-main-container">
                    <div class="fatigue-plot" :style="{ width: plotWidth }">
                        <canvas id="bar-chart"></canvas>
                    </div>
                </div>

                <div v-if="!fixtureViewSelected && flagsData.length > 0" id="fatigue-flag-table"
                    @scroll="tableScrolled">
                    <table>
                        <thead>
                            <tr class="upper-header">
                                <th class="fatigue-flag-names fatigue-flag-names-heading"></th>
                                <th v-for="(title, key) in shortTitles" :key="key" style="padding-top: 40px;">
                                    <span class="metric-tooltips">
                                        {{ title }}
                                        <span class="metric-definition fatigue-hover">{{ longTitles[key] }}</span>
                                    </span>
                                </th>
                            </tr>
                            <tr class="upper-header">
                                <th class="fatigue-flag-names fatigue-flag-names-heading"></th>
                                <th v-for="(date, key) in sessionDates" :key="key">{{ date }}</th>
                            </tr>
                        </thead>
                        <tbody style="text-align:center">
                            <tr v-for="(flags, key) in flagsData" v-bind:key="key">
                                <td class="fatigue-flag-names" v-bind:class="{ 'has-shadow': !tableScrolledLeft }">
                                    <div class="avatar-wrapper">
                                        <div class="avatar-description">
                                            <p>
                                                {{ flags.name }}
                                            </p>
                                        </div>
                                    </div>
                                </td>
                                <td v-for="(date, key) in sessionDates" :style="displayBorder[key]  ? 'border-left: 8px solid var(--bg-primary)' : ''"  v-bind:key="key">
                                    <div :class="flags.data[key].value">
                                        <div class="metric-tooltips" v-if="!isPastFixture[key]">
                                            <span class="metric-definition"
                                                style="width: auto; text-align:left; right: -70px;">
                                                End Date: {{ flagEndDates[key] }} <br>
                                                Total Season Flags: {{ flags.details.seasonFlags }} <br>
                                                Most Recent Flag: {{ flags.details.mostRecent }} <br>
                                                {{ flags.data[key].explanation }}
                                            </span>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { errorHandler } from "@/components/ErrorHandler";
import { Chart } from "chart.js";
import { UserData } from "@/components/UserData";
import UpdateSetting from "@/utils/UpdateSetting";
import MissingDataText from '@/components/MissingDataText.vue';
import Colours from '@/utils/Colours';
import { FLAGS } from "@/utils/FatigueFlagsValues";
import WellnessHeader from "./Wellness/WellnessHeader";
import { loadSelectedSquadIds } from "@/utils/Squad";
import { getSeasons } from "@/utils/Seasons";
import DateUtils from "@/utils/DateUtils";

export default {
    components : {
        MissingDataText,
        WellnessHeader
    },
    data() {
        return {
            tableScrolledLeft: true,
            tableScrolledRight: false,
            playerNames: [],
            flagsData: [],
            sessionDates: [],
            shortTitles: [],
            longTitles: [],
            isWIP: UserData.isWIP(),
            currentlyFlaggedPlayers: [],
            upcomingFixturesFlaggedPlayers: [],
            fixtureViewSelected: false,
            plotWidth: null,
            seasonStart: null,
            seasonEnd: null,
            flagEndDates: [],
            squadsSelected: [],
            selectedPlayersLength: null,
            upcomingFixtureFatigueIsEmpty: null,
            customerId: UserData.customerId(),
            fatigueInsightList: null,
            upcomingFixtures: [],
            sessions: [],
            playersFatigueFlags: [],
            sessionFatigueFlagsCount: null,
            playerFatigueFlags: null,
            fatigueFlagInsights: null,
            upcomingFixturesAmberList: [],
            upcomingFixturesRedList: [],
            mostRecentGame: null,
            isPastFixture: [],
            displayBorder: [],
            upcomingFixtureRedListIsEmpty:null,
            displayInsights: false,
        };
    },
    methods: {
        navigateTo(newPage) {
            this.$router.push(newPage).catch(() => { });
        },
        async refreshData() {
            await this.$root.executeTaskWithProgressBar(async () => {
                await this.getFixtures();
                await this.getFatigueFlagData();
                await this.getFatiguedPlayersList();
                await this.getFatigueFlagPlayersView();
                await this.getFatigueFlagInsights();
                await this.getFatigueFlagSessionsView();
                this.fatigueFlagScroll();
                this.scrollPlot();
            });
        },
        fatigueFlagScroll() {
            const fatigueFlagTable = document.getElementById("fatigue-flag-table");
            if (fatigueFlagTable) {
                const width = fatigueFlagTable.clientWidth;
                fatigueFlagTable.scrollLeft += width * 200;
            }
        },
        scrollPlot() {
            if (this.selectedPlayersLength > 0) {
                const plot = document.getElementById("fatigue-plot-main-container");
                plot.style.display = this.fixtureViewSelected ? "" :  "none";
                if (plot) {
                    const width = plot.clientWidth;
                    plot.scrollLeft += width * 200;
                }
            }
        },
        async togglePlots() {
            this.fixtureViewSelected = !this.fixtureViewSelected;
            await this.scrollPlot();
            this.fatigueFlagScroll();
        },
        tableScrolled() {
            // determine if the table is scrolled hard left or hard right
            const scrollTable = document.getElementById("fatigue-flag-table");
            if (scrollTable != null) {
                this.tableScrolledLeft = scrollTable.scrollLeft === 0;
                this.tableScrolledRight = scrollTable.scrollLeft === scrollTable.scrollWidth - scrollTable.clientWidth;
            }

        },
        async getFatigueFlagData() {
            const response = await this.$root.webApiGet( `/fatigueflags?customerId=${this.customerId}` );

            if (response.status === 200) {
                this.playerFatigueFlags = response.data.playerFatigueFlags;
                this.fatigueFlagInsights = response.data.fatigueFlagInsights;
                this.sessionFatigueFlagsCount = response.data.sessionFatigueFlagsCount;
                this.playerNames = this.playerFatigueFlags.map(d => d.player);

            } else {
                errorHandler.error(response, this);
            }
        },
        async getFatiguedPlayersList() {
            const playerFatigueFlags = this.playerFatigueFlags;
            const players = this.playerNames;
            const selectedSquads = loadSelectedSquadIds();
            const fatigueFlags = playerFatigueFlags.map(t => t.fatigueFlags);
            const playersFatigueFlags = this.playersFatigueFlags;
            this.selectedPlayersLength = players.filter(t => selectedSquads.includes(t.squadId)).length;
            const currentlyFlaggedPlayers = [];
            const mostRecentMatches = [];
            this.getSessionsList(fatigueFlags);

            for (let i = 0 ; i < fatigueFlags.length; i++) {
                const fatigueFlagValues = fatigueFlags[i].map(t => t.fatigueValue);
                if (!fatigueFlagValues.every(t => t == null)) { //retrieve data for players with at least 1 flag of any colour
                    const getPlayerAttribute = attribute => players[i][attribute];
                    const getFatigueFlagAttribute = attribute => fatigueFlags[i].map(t => t[attribute]);
                    playersFatigueFlags.push({
                        'name': getPlayerAttribute('name'),
                        'squadId': getPlayerAttribute('squadId'),
                        'value': getFatigueFlagAttribute('fatigueValue'),
                        'date': getFatigueFlagAttribute('date'),
                        'positionGroupId': getPlayerAttribute('positionGroupId'),
                        'explanatoryText': getFatigueFlagAttribute('explanatoryText')
                    });
                }
            }

            const tenDaysAgo = DateUtils.subtractDays(new Date(), 10);
            
            for (const key in playersFatigueFlags) {
                const playerFatigueFlag = playersFatigueFlags[key];
                const sessionDates = playerFatigueFlag.date;

                const upcomingFixtureDates = this.upcomingFixtures.map(t => t.date);
                const lastMatchDate = sessionDates[sessionDates.length - 1];
                const lastMatchFlagValue = playerFatigueFlag.value[playerFatigueFlag.value.length - 1];
                mostRecentMatches.push(new Date(lastMatchDate));
                const hasPlayedInLastTenDays = tenDaysAgo <= new Date(lastMatchDate);

                if (hasPlayedInLastTenDays && (lastMatchFlagValue === FLAGS.AMBER || lastMatchFlagValue === FLAGS.RED)) { //played in the last 10 days & is flagged
                    const flagEndDate = DateUtils.addDays(lastMatchDate, 10); // Calculate the flag end date (10 days after the last flagged session)
                    const willHaveFlagInFixture = this.upcomingFixtures.map(t => flagEndDate >= new Date(t.date)); //Determine if player will have flag in upcoming fixtures
                    const playerUpcomingFixtureDates = willHaveFlagInFixture.map((t, i) => { if (t) return upcomingFixtureDates[i]; }); //If player is still flagged in upcoming fixture(s) return the fixture date(s)
                    //if player has a list of upcoming fixtures, add fixtures and apply current flag value for them
                    sessionDates.push(...playerUpcomingFixtureDates);
                    for (let index = 0; index < playerUpcomingFixtureDates.length; index++) {
                        playerFatigueFlag.value.push(lastMatchFlagValue);
                    }
                    
                    currentlyFlaggedPlayers.push({
                        name: playerFatigueFlag.name,
                        positionGroupId: playerFatigueFlag.positionGroupId,
                        startDate: lastMatchDate,
                        endDate: new Date(flagEndDate).toISOString().split('T')[0],
                        colour: lastMatchFlagValue === FLAGS.AMBER ? Colours.AMBER : Colours.RED,
                        value: lastMatchFlagValue,
                        upcomingFixtureDates: upcomingFixtureDates,
                        willHaveFlag: willHaveFlagInFixture
                    });
                }
            }

            this.mostRecentGame = new Date(Math.max(...mostRecentMatches));
            const sortedFlaggedPlayers = [...currentlyFlaggedPlayers].sort((a, b) => b.value - a.value || new Date(b.startDate) - new Date(a.startDate));
            if (this.currentlyFlaggedPlayers.length === 0) this.currentlyFlaggedPlayers = sortedFlaggedPlayers;
            
            if (this.upcomingFixturesFlaggedPlayers.length === 0) {
                for (const key in sortedFlaggedPlayers) {
                    if (sortedFlaggedPlayers[key].willHaveFlag.includes(true)) { // If the player will be flagged in at least one upcoming fixture in the next 10 days, add the player to the upcomingFixturesFlaggedPlayers
                        this.upcomingFixturesFlaggedPlayers.push(sortedFlaggedPlayers[key]);
                    }
                }
            }

            this.upcomingFixturesAmberList = this.extractFilteredFlaggedPlayersAttributeList(FLAGS.AMBER, 'name');
            this.upcomingFixturesRedList = this.extractFilteredFlaggedPlayersAttributeList(FLAGS.RED, 'name');
            this.upcomingFixtureRedListIsEmpty = this.upcomingFixturesRedList.length === 0;

        },
        async getFatigueFlagInsights() {
            const fatigueFlagInsights = this.fatigueFlagInsights;
            const uniquePositionGroups = [...new Set(this.extractFilteredFlaggedPlayersAttributeList(FLAGS.RED, 'positionGroupId'))]; //get all unique position groups
            this.upcomingFixtureFatigueIsEmpty = this.upcomingFixturesAmberList.length === 0 && this.upcomingFixturesRedList.length === 0;

            if (uniquePositionGroups.length > 0) {
                const defaultPositionsInsight = fatigueFlagInsights.filter(t => t.positionGroupId == null); //get insights where group id is null 
                const knownPositionsInsight = uniquePositionGroups.map(t => fatigueFlagInsights.filter(i => t === i.positionGroupId));

                //get insights for positions and sort by priority
                const sortedInsights = [...new Set(knownPositionsInsight.map(t => t.length === 0 ? defaultPositionsInsight : t).flat().sort((a, b) => a.priority - b.priority))];

                //Group fatigue insights by position group ids
                const insightsGroupedByPosition = sortedInsights.reduce((acc, insight) => {
                    if (!Object.keys(acc).includes(insight.positionGroupId)) {
                        acc[insight.positionGroupId] = sortedInsights.filter(g => g.positionGroupId === insight.positionGroupId);
                    }
                    return acc;
                }, {});

                const fatigueInsights = [];
                let counter = 0;
                while (fatigueInsights.length < 3) { // checks that we have < 3 fatigueInsights to prevent infinite loop
                    const index = counter++;
                    for (const key in insightsGroupedByPosition) {
                        fatigueInsights.push(insightsGroupedByPosition[key][index]); //get insight at index position
                    }
                }

                const DEFENDERS = 2;
                const MIDFIELDERS = 3;
                const FORWARDS = 4;

                fatigueFlagInsights.map(t => {
                    if (t.positionGroupId === DEFENDERS) {
                        t.positionGroupText = 'Defenders';
                    } else if (t.positionGroupId === MIDFIELDERS) {
                        t.positionGroupText = 'Midfielders';
                    } else if (t.positionGroupId === FORWARDS) {
                        t.positionGroupText = 'Forwards';
                    } else {
                        t.positionGroupText = 'Players';
                    }
                });
                // get insights of players flagged in the next fixture
                this.fatigueInsightList = fatigueInsights.flat().splice(0, 3); //get rid of nesting & get first 3 insights 
            }
        },
        async getFatigueFlagPlayersView() {
            const playersFatigueFlags = this.playersFatigueFlags;
            this.sessions.sort((a, b) => { return new Date(a.date) - new Date(b.date); });

            if (this.upcomingFixturesFlaggedPlayers.length > 0) { //Only add upcoming fixtures column when players are flagged in upcoming fixtures
                for (const key in this.upcomingFixtures) { //if we have upcoming fixtures get the date and title
                    const upcomingFixture = this.upcomingFixtures[key];
                    this.sessions.push({ date: upcomingFixture.date, title: upcomingFixture.description }); //add upcoming fixtures' date & titles
                }
            }

            const titles = [];
            const sessionDates = [];
            const endDates = [];
            const isUpcomingFixture = [];

            for (const key in this.sessions) {
                const sessionDate = this.sessions[key].date;
                titles.push(this.sessions[key].title);
                sessionDates.push(sessionDate);
                isUpcomingFixture.push(DateUtils.getToday() <= new Date (sessionDate)); //enable hover details when fixture has been played
                const fullSessionDate = DateUtils.addDays(sessionDate, 10);
                endDates.push(fullSessionDate.toISOString().split('T')[0]);
            }

            if (isUpcomingFixture.includes(true)) {
                const displayBorder = new Array(isUpcomingFixture.length).fill(false); //create and fill array with false values
                const nextFixture = isUpcomingFixture.indexOf(true); //get position of next fixture
                displayBorder.splice(nextFixture, 1, true); //find position of next fixture and set to true
                this.displayBorder = displayBorder;
            }

            //Fatigue table titles
            this.shortTitles = titles.map(t => t.includes("(") ? t.substr(0, t.indexOf('(')) : t);
            this.longTitles = titles.map(t => t);
            this.sessionDates = sessionDates;
            this.flagEndDates = endDates;
            this.isPastFixture = isUpcomingFixture;

            for (const key in playersFatigueFlags) {
                const playerData = playersFatigueFlags[key];
                const playersRowData = [];

                const getFlagColour = (value, isFuture) => {
                    if (value === FLAGS.RED && !isFuture) {
                        return 'red-flag';
                    } else if (value === FLAGS.AMBER && !isFuture) {
                        return 'amber-flag';
                    } else if (value === FLAGS.GREEN && !isFuture) {
                        return 'green-flag';
                    } else if (value === FLAGS.AMBER && isFuture) {
                        return 'f-circle amber-flag';
                    } else if (value === FLAGS.RED && isFuture) {
                        return 'f-circle red-flag';
                    } else {
                        return 'no-flag';
                    }
                };

                for (const key in this.sessionDates) {
                    const index = playerData.date.indexOf(this.sessionDates[key]);
                    const mostRecentGame = this.mostRecentGame;
                    const date = this.sessionDates[key];
                    let value = null;
                    let explanation = null;
                    let additionalClass = '';

                    if (index >= 0) {
                        value = playerData.value[index];
                        if (playerData.explanatoryText[index]) {
                            explanation = 'Context: ' + playerData.explanatoryText[index];
                            if (value === FLAGS.RED) {
                                additionalClass = ' animated-red-flag';
                            }
                        }
                    }
                    const isFuture = new Date(date) > mostRecentGame;
                    const colour = getFlagColour(value, isFuture);
                    playersRowData.push({ key: date, value: colour + additionalClass, date, explanation });
                }

                const seasonData = playerData.date.map((t, i) => {
                    if ((new Date(t) >= new Date(this.seasonStart) && new Date(t) <= new Date(this.mostRecentGame))) {
                        return i;
                    }
                });
                
                const seasonFlagValues = playerData.value.filter((t, i) => seasonData.includes(i));
                const seasonDates = playerData.date.filter((t, i) => seasonData.includes(i));
                const thirdMostRecentMatch = this.sessionDates.filter(t => new Date(t) <= new Date(this.mostRecentGame)).slice(-3)[0];
                const hasPlayedInLastThreeMatches = seasonDates.slice(-3).map(t=> new Date(t) >= new Date(thirdMostRecentMatch)).includes(true); //checks if player has featured at least once in last three matches
                const hasFlagInLastThreeMatches = hasPlayedInLastThreeMatches ? (seasonFlagValues.slice(-3).every(t=> t == null) ? 1 : 0) : 1; // return 0 if player has featured and had a flag in at least one game
                const indexOfAllRedFlags = [];
                if (seasonFlagValues.filter(t => t === FLAGS.RED).length > 1) {
                    for (const key in seasonFlagValues) {
                        if (seasonFlagValues[key] === FLAGS.RED) {
                            indexOfAllRedFlags.push(key);
                        }
                    }
                }
                
                const secondMostRecentRedFlag = seasonDates[indexOfAllRedFlags[indexOfAllRedFlags.length - 2]];
                const mostRecentRedFlag = indexOfAllRedFlags.length > 0 ? secondMostRecentRedFlag : 'N/A';
                const seasonRedFlags = seasonFlagValues.filter(t => t === FLAGS.RED).length;
                const details = { mostRecent: mostRecentRedFlag, seasonFlags: seasonRedFlags };
                const tableData = { name: playerData.name, data: playersRowData, details: details, squadId: playerData.squadId, rank: hasFlagInLastThreeMatches };
                const selectedSquads = loadSelectedSquadIds();
                if (selectedSquads.includes(tableData.squadId)) {
                    this.flagsData.push(tableData);
                }
            }
                this.flagsData.sort((a,b) => a.rank - b.rank); //sort table
        },
        extractFilteredFlaggedPlayersAttributeList(flagValue, playerAttribute) {
            return [...this.upcomingFixturesFlaggedPlayers].filter(t => t.value === flagValue).map(t => t[playerAttribute]);
        },
        async getFatigueFlagSessionsView() {
            if (this.selectedPlayersLength > 0 ) {
                const playersFatigueFlags = this.playersFatigueFlags;
                const sessionFatigueFlagsCount = this.sessionFatigueFlagsCount.filter(t=> this.sessionDates.includes(t.date));
                if (this.upcomingFixturesFlaggedPlayers.length > 0) {
                    for (const key in this.upcomingFixtures) {
                        const amberCount = this.upcomingFixturesFlaggedPlayers.filter(t => t.value === FLAGS.AMBER && t.willHaveFlag[key]).length;
                        const redCount = this.upcomingFixturesFlaggedPlayers.filter(t => t.value === FLAGS.RED && t.willHaveFlag[key]).length;
                        sessionFatigueFlagsCount.push({
                            title: this.upcomingFixtures[key].description,
                            date: this.upcomingFixtures[key].date,
                            amberCount,
                            redCount,
                            isPastFixture: true
                        });
                    }
                }

                const getFlaggedPlayerNames = (date, value) => { 
                    const names = [];
                    for (const key in playersFatigueFlags) {
                        const sessionDate = playersFatigueFlags[key].date.includes(date);
                        if (sessionDate) {
                            const sessionDateIndex = playersFatigueFlags[key].date.indexOf(date);
                            if (playersFatigueFlags[key].value[sessionDateIndex] === value) {
                                names.push(playersFatigueFlags[key].name);
                            }
                        }
                    }
                    return names; //return names of players that are flagged on hover over session bar
                };
                
                const redCounts = [];
                const amberCounts = [];
                const labelDates = [];
                const hoverDetails = [];
                const upcomingMatchShading = [];
                const labels = [];
                const emptySessions = [];

                for (const key in sessionFatigueFlagsCount) {
                    const session = sessionFatigueFlagsCount[key];
                    const sessionAmberCount = session.amberCount;
                    const sessionRedCount = session.redCount;
                    const sessionTitle = session.title;
                    const sessionDate = session.date;
                    const isPastFixture = session.isPastFixture;

                    redCounts.push(sessionRedCount);
                    amberCounts.push(sessionAmberCount);
                    labelDates.push(sessionDate);
                    hoverDetails.push([sessionTitle, sessionDate]);
                    upcomingMatchShading.push(isPastFixture ? 0.7 : 0);
                    labels.push([sessionTitle.includes("(") ? sessionTitle.substr(0, sessionTitle.indexOf('(')) : sessionTitle, sessionDate]);
                    emptySessions.push((sessionAmberCount === null && sessionRedCount === null) || 
                    (isPastFixture && sessionAmberCount === 0 && sessionRedCount === 0) ? 0.8 : 0);
                }
                this.plotWidth = ((sessionFatigueFlagsCount.length + 1) * 10) + '%';
                const combinedFlags = redCounts.concat(amberCounts);
                const averageLine = Math.round((combinedFlags.reduce((a, b) => a + b, 0) / redCounts.length));

                const barChartCtx = document.getElementById('bar-chart').getContext("2d");

                const createDiagonalPattern = colour => { 
                    const shape = document.createElement('canvas');
                    shape.width = 20;
                    shape.height = 20;
                    const c = shape.getContext('2d');
                    c.fillStyle = '#ffbf00';
                    c.fillRect(0, 0, shape.width, shape.height);
                    c.strokeStyle = colour;
                    c.lineWidth = 2;
                    c.beginPath();
                    c.moveTo(4, 0);
                    c.lineTo(20, 16);
                    c.stroke();
                    c.beginPath();
                    c.moveTo(0, 16);
                    c.lineTo(4, 20);
                    c.stroke();

                    return c.createPattern(shape, 'repeat');
                };

                new Chart(barChartCtx, {
                    type: "bar",
                    data: {
                        labels: labels, hoverDetails,
                        datasets: [
                            {
                                label: 'Amber',
                                backgroundColor: createDiagonalPattern('#ffd75e'),
                                color: '#ffbf00',
                                data: amberCounts, hoverDetails, labelDate: labelDates,
                                barPercentage: 1,
                            },
                            {
                                label: 'Red',
                                backgroundColor: "#FF0000",
                                data: redCounts, hoverDetails, labelDate: labelDates,
                                barPercentage: 1,
                            },
                            {
                                label: '',
                                backgroundColor: "rgba(219, 219, 219, 0)",
                                data: emptySessions,
                                borderColor: "rgba(219, 219, 219, 0.2)",
                                barPercentage: 1,
                                borderWidth: 2,
                            },
                            {
                                label: '',
                                backgroundColor: "rgba(219, 219, 219, 0.2)",
                                data: upcomingMatchShading,
                                barPercentage: 1,

                            },
                            {
                                label: '',
                                backgroundColor: "rgba(219, 219, 219, 0.2)",
                                data: upcomingMatchShading.map(t=> - Math.abs(t)),
                                barPercentage: 1,
                            }

                        ],
                    },
                    options: {
                        responsive: true,
                        maintainAspectRatio: false,
                        title: {
                            display: false,
                            text: 'Fixture',
                            fontSize: 14
                        },
                        legend: {
                            display: false,
                            onClick: (e) => e.stopPropagation()
                        },
                        scales: {
                            yAxes: [{
                                stacked: true,
                                ticks: {
                                    beginAtZero: true,
                                    display: true,
                                    lineAtIndex: 5,
                                    // stepSize: 1,
                                    callback: value => {
                                        return value === averageLine ? 'Avg: ' + value : null;
                                    }
                                },
                                gridLines: {
                                    display: true,
                                    color: '#fff',
                                    lineWidth: 1,
                                    zeroLineWidth: 0,
                                    drawBorder: false

                                }
                            }],
                            xAxes: [{
                                stacked: true,

                                gridLines: {
                                    display: false,
                                },
                                ticks: {
                                    fontSize: 10,
                                    autoSkip: false,
                                    maxRotation: 0,
                                    padding: 10
                                }
                            }]
                        },
                        tooltips: {
                            enabled: true,
                            mode: 'nearest',
                            filter: function (tooltipItem) {
                                return tooltipItem.datasetIndex !== 3 && tooltipItem.datasetIndex !== 4;
                            },
                            callbacks: {
                                title: function () { },
                                label: (tooltipItem) => {
                                    if (tooltipItem.datasetIndex !== 2) {
                                        return tooltipItem.yLabel;
                                    }
                                },
                                afterLabel: (tooltipItem, data) => {
                                    const barLabel = data.datasets[1].hoverDetails[tooltipItem.index];
                                    const sessionDate = data.datasets[1].labelDate[tooltipItem.index];

                                    if (tooltipItem.datasetIndex === 1) {
                                        return ['Player(s) : ' + getFlaggedPlayerNames(sessionDate, FLAGS.RED), barLabel[0], barLabel[1]];
                                    } else if (tooltipItem.datasetIndex === 0) {
                                        return ['Player(s) : ' + getFlaggedPlayerNames(sessionDate, FLAGS.AMBER), barLabel[0], barLabel[1]];
                                    } else {
                                        return 'No flagged players';
                                    }

                                }
                            }
                        },
                    }
                });
            }

        },
        async getFixtures() {
            const currentSeason = getSeasons(new Date())[0];
            const currentSeasonStart = currentSeason.startDate.toISOString().substring(0, 10);
            const currentSeasonEnd = currentSeason.endDate.toISOString().substring(0, 10);

            const response = await this.$root.webApiGet(
                `/fixtures?customerId=${this.customerId}&startDate=${currentSeasonStart}&endDate=${currentSeasonEnd}`
            );

            if (response.status === 200) {
                const fixtures = response.data.fixtures;
                const tenDaysFromToday = DateUtils.addDays(new Date(), 10);
                this.upcomingFixtures = fixtures.filter(t => {
                    const fixtureDate = new Date(t.date);
                    return fixtureDate >= new Date() && fixtureDate <= tenDaysFromToday; //Get all fixtures in the next 10 days
                });
                this.seasonStart = currentSeasonStart;
                this.seasonEnd = currentSeasonEnd;

            } else {
                errorHandler.error(response, this);
            }
        },
        getSessionsList(fatigueFlags) {
            const uniqueSessionIds = new Set();
            fatigueFlags.forEach(flag => {
                // Loop through the fatigue flag details and add only unique sessions to the sessions array
                flag.forEach(f => {
                    if (!uniqueSessionIds.has(f.sessionId)) {
                        uniqueSessionIds.add(f.sessionId);
                        this.sessions.push({
                            'date': f.date,
                            'title': f.title,
                            'sessionId': f.sessionId
                        });
                    }
                });
            });
        }

    },

    async mounted() {
        console.log("FatigueFlag.vue mounted");
        this.$root.newPageView("Fatigue Flag Page", UserData.userName());
        await this.refreshData();
        this.squadsSelected = loadSelectedSquadIds();
        UpdateSetting();
    }
};
</script>