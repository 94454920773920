<template>
    <!-- prettier-ignore -->
    <div id="content-wrap" v-if="!$root.isLoadingData" style="margin-left:90px">
        <div id="content" class="pt-4 pb-4 pl-3 pr-3">
            <div style="position: relative; height: auto; margin-left:15px; margin-right:15px;" class="container-fluid">
                <div style="display: flex; justify-content: center;">
                    <RadioButton :id="'season-radio-group'" :modal="seasonSelected.text" :options="seasonOptions"
                        :label="''" :name="'season-radio-options'" @update-modal="seasonSelected = lookupSeason($event)"
                        :task="filterSeasons" />
                </div>
                <p v-if="sessionsList.length === 0" style="text-align:center"> No data for this season </p>
                <div v-if="sessionsList.length > 0">
                    <div id="data-table">
                        <table>
                            <thead>
                                <tr id="header">
                                    <th v-bind:class="{
                                            sortedAsc: sortedColumn === 0 && sortOrder === 1,
                                            sortedDesc: sortedColumn === 0 && sortOrder === 2,
                                        }" @click="sortByColumnToggle(0)">
                                        Session date
                                        <svg class="sort-arrow" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 11.41 7.12">
                                            <polyline points="10.71 0.71 5.71 5.71 0.71 0.71" fill="none" stroke="#fff"
                                                stroke-miterlimit="10" stroke-width="2"></polyline>
                                        </svg>
                                    </th>
                                    <th v-bind:class="{
                                            sortedAsc: sortedColumn === 1 && sortOrder === 1,
                                            sortedDesc: sortedColumn === 1 && sortOrder === 2,
                                        }" @click="sortByColumnToggle(1)">
                                        Title
                                        <svg class="sort-arrow" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 11.41 7.12">
                                            <polyline points="10.71 0.71 5.71 5.71 0.71 0.71" fill="none" stroke="#fff"
                                                stroke-miterlimit="10" stroke-width="2"></polyline>
                                        </svg>
                                    </th>
                                    <th v-bind:class="{
                                            sortedAsc: sortedColumn === 2 && sortOrder === 1,
                                            sortedDesc: sortedColumn === 2 && sortOrder === 2,
                                        }" @click="sortByColumnToggle(2)">
                                        Type
                                        <svg class="sort-arrow" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 11.41 7.12">
                                            <polyline points="10.71 0.71 5.71 5.71 0.71 0.71" fill="none" stroke="#fff"
                                                stroke-miterlimit="10" stroke-width="2"></polyline>
                                        </svg>
                                    </th>
                                    <th v-bind:class="{
                                            sortedAsc: sortedColumn === 3 && sortOrder === 1,
                                            sortedDesc: sortedColumn === 3 && sortOrder === 2,
                                        }" @click="sortByColumnToggle(3)">
                                        Status
                                        <svg class="sort-arrow" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 11.41 7.12">
                                            <polyline points="10.71 0.71 5.71 5.71 0.71 0.71" fill="none" stroke="#fff"
                                                stroke-miterlimit="10" stroke-width="2"></polyline>
                                        </svg>
                                    </th>
                                    <th v-bind:class="{
                                            sortedAsc: sortedColumn === 4 && sortOrder === 1,
                                            sortedDesc: sortedColumn === 4 && sortOrder === 2,
                                        }" @click="sortByColumnToggle(4)">
                                        <polyline points="10.71 0.71 5.71 5.71 0.71 0.71" fill="none" stroke="#fff"
                                            stroke-miterlimit="10" stroke-width="2"></polyline>
                                    </th>
                                </tr>
                            </thead>
                            <tbody v-for="session in sessionsList" v-bind:key="session.sessionId">
                                <tr>
                                    <td>{{ session.date }}</td>
                                    <td>
                                        {{ session.title }}
                                    </td>
                                    <td>
                                        <span :style="{ borderColor: session.typeColour }"
                                            class="pill" id="session-type">{{ session.type }}
                                        </span>
                                    </td>
                                    <td
                                        :style="{ color: session.statusDisplayName === 'Complete' ? '#7AC943' : '#FBD30D' }">
                                        {{ session.statusDisplayName }}
                                    </td>
                                    <td>
                                        <a @click="editSession(session.sessionId)" v-b-modal.session-edit-modal
                                            style="cursor:pointer;">EDIT</a>
                                    </td>
                                    <td>
                                        <router-link
                                            :style="{ display: (session.statusDisplayName === 'Complete' && !session.isLocked) ? '' : 'none' }"
                                            :to="matchOverviewPath + customerId + '/' + session.sessionId">
                                            VIEW
                                        </router-link>
                                    </td>
                                    <td class="px-2">
                                        <DownloadButton
                                            v-if="!session.isLocked && hasSMRDownload && session.hasMatchReport"
                                            :session="session" title='SMR' filePrefix='Sportlight_Match_Report'
                                            fileType='pdf' api='matchreport' />
                                    </td>
                                    <td class="px-2">
                                        <DownloadButton
                                            v-if="!session.isLocked && hasXMLDownload && session.hasTurnsEnhanced"
                                            :session="session" title='XML' filePrefix='Sportlight_Video_XML'
                                            fileType='xml' api='videoxml' />
                                    </td>
                                    <td>
                                        <span :style="{ display: session.isLocked ? '' : 'none' }">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                                                stroke-linecap="round" stroke-linejoin="round"
                                                class="feather feather-lock">
                                                <rect x="3" y="11" width="18" height="11" rx="2" ry="2"></rect>
                                                <path d="M7 11V7a5 5 0 0 1 10 0v4"></path>
                                            </svg>
                                        </span>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
        <EditModal :cancel="cancelEditSession" :id="'session-edit-modal'" :title="'Edit Session'"
            :cancelVariant="'secondary standard-btn'" :ok=acceptEditSession>
            <div class="alert alert-danger" v-if="this.editWarningText">
                {{ this.editWarningText }}
            </div>
            <fieldset>
                <div class="form-group">
                    <label for="edit-title">Title</label>
                    <input type="text" id="edit-title" name="edit-title" class="form-control"
                        placeholder="Enter session title" v-model="editTitle" />
                </div>
                <div class="form-group">
                    <label for="edit-type">Session type</label>
                    <b-form-select id="edit-type" v-model="editType" :options="editTypeOptions"></b-form-select>
                </div>
            </fieldset>
        </EditModal>
    </div>
</template>
<script>
import { errorHandler } from "@/components/ErrorHandler";
import { UserData } from "@/components/UserData";
import EditModal from '@/components/EditModal.vue';
import RadioButton from '@/components/RadioButton.vue';
import { getSeasons, Season } from "@/utils/Seasons";
import configStore from "@/store/config";
import DownloadButton from "@/components/DownloadButton.vue";

export default {
    components: {
        EditModal,
        RadioButton,
        DownloadButton
    },
    data() {
        return {
            sessionsList: [],
            sortedColumn: 0,
            sortOrder: 0,
            columnProperties: ["date", "title", "type", "statusDisplayName"],
            editId: 0,
            editTitle: "",
            editType: null,
            editModal: false,
            editTypeOptions: [],
            editDefaultTitle: "",
            editWarningText: "",
            isActive: false,
            showSeason: false,
            seasonSelected: Season.ALL,
            seasonOptions: null,
            customerId: UserData.customerId(),
            hasSMRDownload: UserData.hasSMRDownload(),
            hasXMLDownload: UserData.hasXMLDownload(),
            matchOverviewPath: UserData.useReportingForSession() ? '/sessionreporting/' : '/session/'
        };
    },

    methods: {
        navigateTo(newPage) {
            this.$router.push(newPage).catch(() => { });
        },
        filterSeasons() {
            this.getSessionsList();
        },
        sortByColumnToggle(column) {
            // toggle the sort order
            if (this.sortedColumn === column) {
                if (this.sortOrder === 1) {
                    this.sortOrder = 2;
                } else {
                    this.sortOrder = 1;
                }
            } else {
                this.sortedColumn = column;
                this.sortOrder = 1;
            }
            this.sort();
        },
        sort() {
            const propertyName = this.columnProperties[this.sortedColumn];
            if (this.sortOrder === 1) {
                // sort descending
                this.sessionsList.sort((a, b) =>
                    this.compare(a[propertyName], b[propertyName])
                );
            } else if (this.sortOrder === 2) {
                // sort ascending
                this.sessionsList.sort((a, b) =>
                    this.compare(b[propertyName], a[propertyName])
                );
            }
        },
        compare(a, b) {
            // sort comparison helper
            if (a > b) return +1;
            if (a < b) return -1;
            return 0;
        },
        async editSession(sessionId) {
            this.editDefaultTitle = await this.getDefaultTitle(sessionId);
            this.editId = sessionId;
            const session = this.sessionsList.find(
                s => s.sessionId === sessionId
            );
            this.editTitle = !session.title ? this.editDefaultTitle : session.title;
            this.editType = session.sessionType.id;
            this.editModal = true;
        },
        async acceptEditSession() {
            this.editWarningText = "";

            if (!this.editTitle && this.editType === 7) {
                this.editTitle = this.editDefaultTitle; // default to fixture description if match selected and no title entered
            }
            const json = { sessionId: this.editId, customerId: this.customerId, title: this.editTitle, sessionTypeId: this.editType };
            const response = await this.$root.webApiPost(`/session`, json);
            if (response.status === 200) {
                this.editModal = false;
                this.editId = 0;
                await this.getSessionsList();
            } else {
                errorHandler.error(response, this);
            }
        },
        cancelEditSession() {
            this.editId = 0;
            this.editModal = false;
        },
        async getSessionsList() {
            /* TODO -separate out data retrieval from filtering so that we don't call the back end each time we change 
                the season selected! */

            const response = await this.$root.webApiGet(`/sessions?customerId=${this.customerId}`);

            if (response.status === 200) {
                const sessionsList = response.data;

                if (sessionsList.length > 0) {
                    const earliestDate = new Date(Math.min(...sessionsList.map(session => new Date(session.date).valueOf())));
                    this.seasonOptions = getSeasons(earliestDate, true);
                    this.sessionsList = sessionsList.filter(session => this.seasonSelected.contains(new Date(session.date)));
                } else {
                    this.seasonOptions = [Season.ALL];
                    this.seasonSelected = Season.ALL;
                    this.sessionsList = [];
                }
            } else {
                errorHandler.error(response, this);
            }
        },
        lookupSeason(seasonText) {
            return this.seasonOptions.find(season => season.text === seasonText);
        },
        async getSessionTypes() {
            configStore.state.sessionTypes.forEach(st => {
                this.editTypeOptions.push({
                    value: st.id,
                    text: st.name,
                    colour: st.colour
                });
            });
        },
        async getDefaultTitle(sessionId) {
            const session = this.sessionsList.find(s => s.sessionId === sessionId);
            const response = await this.$root.webApiGet(
                `/fixtures?customerId=${this.customerId}&startDate=${session.date}&endDate=${session.date}`
            );
            if (response.status === 200) {
                return response.data.fixtures.length > 0 ? response.data.fixtures.description : "";
            } else {
                errorHandler.error(response, this);
            }
        }
    },
    async mounted() {
        console.log("Data.vue mounted");
        this.$root.newPageView("Data Page", UserData.userName());
        await this.$root.executeTaskWithProgressBar(async () => {
            await this.getSessionTypes();
            await this.getSessionsList();
        });
    }
};
</script>