<template>
    <div style="position: relative" v-show="hasKpiValues">
        <div class="chart-container">
            <h1 class="label-container">
                <span class="metric-tooltips">
                    {{ title }}
                    <span class="metric-definition"> {{ hoverText }} </span>
                </span>
            </h1>
            <div>
                <div class="chart-label">
                    <canvas :id="labelId"></canvas>
                </div>
            </div>
            <div class="chart" :id="chartContainerId">
                <canvas :id="chartId" :class="chartClass"></canvas>
            </div>
        </div>
        <div class="container">
            <span style="margin-right:4px;font-size: x-small;">20</span>
            <div style="width: auto" class="d-flex">
                <div v-for="(k, index) in kpi" :key="index" class="battery" :style="{ backgroundColor: k.color }"></div>
            </div>
            <span style="margin-left:4px; font-size: x-small;">1</span>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        kpi: Array,
        title: String,
        hoverText: String,
        labelId: String,
        chartContainerId: String,
        chartId: String,
        chartClass: String,
        hasKpiValues: Boolean
    }
};
</script>

<style scoped>
.chart-container {
    display: flex;
    position: relative;
    height: 20vh;
    margin: auto;
    width: 85vw;
}

.label-container {
    position: absolute;
    font-size: 14px;
    top: 40%;
    max-width: 25vw;
}

.chart {
    height: 20vh;
    width: 65vw;
}

.chart-label {
    height: 20vh;
    width: 20vw;
}

.container {
    display: flex;
    position: absolute;
    top: 63.4%;
    left: 35%;
    z-index: 1;
    align-items: center;
    max-width: 55vw;
    margin: auto;
}

.battery {
    width: 1.5vw;
    height: 3vh;
    background-color: grey;
    margin: 1px;
}
</style>