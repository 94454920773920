<template>
    <!-- prettier-ignore -->
    <div v-if="!$root.isLoadingData" id="content-wrap" style="margin-left:90px">
        <SessionHeader />

        <div id="content" class="pt-4 pb-4 pl-3 pr-3">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-12" style="display: flex; justify-content: space-between">
                        <h1 id="session-header" class="pb-4">
                            {{ sessionTitle }}
                            <div class="pill ml-4" :style="{ borderColor: sessionTypeColour }">
                                {{ sessionType }}
                            </div>
                        </h1>
                        <SMRButton :session="session"/>
                    </div>
                    <div class="col-12 mb-4" v-if="hasMatchObservationsData">
                        <AutomatedMatchObservation :data=matchObservations />
                    </div>
                </div>
            </div>

            <UnidentifiedPlayers v-if="unidentifiedThumbnails.length > 0" :customerId="customerId" :sessionId="sessionId" 
                :unidentifiedThumbnails="unidentifiedThumbnails" :unusedPlayers="unusedPlayers" 
                :onSaveSuccess="() => {getKpiData(); getUnidentifiedPlayers()}"/>

            <div style="position: relative; height: 100vh; margin-left:15px; margin-right:15px; margin-top:-4vh">
                <div>
                    <div class="pt-4 mb-1">
                        <div class="table-actions">
                            <label class="switch" v-if="!useShirtNumber">
                                <input type="checkbox" data-run-action="single_session_table_group_by_position"
                                    @click="toggleGroup()" />
                                <span class="slider round"></span>
                                <span class="switch-label">Group by position</span>
                            </label>
                            <ExportButton :onClick="exportData" :isDisabled="!hasExportData" />
                            <div id="hidden-container" style="display:none;"></div>
                        </div>
                    </div>

                    <div id="session-table" @scroll="tableScrolled">
                        <table>
                            <thead>
                                <tr class="upper-header">
                                    <th class="fixed-column-left fixed-column-left-heading"></th>
                                    <!-- Optional table headings to match columns below when we do/don't have match times present-->
                                    <th :style="{ display: displaySessionTime }"></th> 
                                    <th :style="{ display: displaySessionTime }"></th>
                                    <th :style="{ display: displayMatchTime }"></th>
                                    <th></th>
                                    <th></th>
                                    <th></th>
                                    <th class="column-separator"></th>
                                    <th :style="{ display: displayBBZones }" colspan="5">Speed Zones</th>
                                    <th :style="{ display: displayBBZones }" class="column-separator"></th>
                                    <th></th>
                                    <th></th>
                                    <th></th>
                                    <th></th>
                                    <th></th>
                                    <th class="column-separator"></th>
                                    <th colspan="3">Decelerations</th>
                                    <th class="column-separator"></th>
                                    <th colspan="3">Accelerations</th>
                                    <th class="column-separator"></th>
                                    <th colspan="3">Acceleration Distance (<MeasurementUnit :type="ShortDistance" />)</th>
                                    <th colspan="3"></th>
                                </tr>
                                <tr>
                                    <th class="fixed-column-left fixed-column-left-heading"> </th>
                                    <th v-bind:class="{
                                        sortedAsc: sortedColumn === 0 && sortOrder === 1,
                                        sortedDesc: sortedColumn === 0 && sortOrder === 2,
                                    }" @click="sortByColumnToggle(0)" :style="{ display: displaySessionTime }">
                                        <span class="metric-tooltips">

                                            Session Time(mins)

                                        </span>
                                        <svg class="sort-arrow" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 11.41 7.12">
                                            <polyline points="10.71 0.71 5.71 5.71 0.71 0.71" fill="none" stroke="#fff"
                                                stroke-miterlimit="10" stroke-width="2"></polyline>
                                        </svg>
                                    </th>
                                    <th v-bind:class="{
                                        sortedAsc: sortedColumn === 1 && sortOrder === 1,
                                        sortedDesc: sortedColumn === 1 && sortOrder === 2,
                                    }" @click="sortByColumnToggle(1)" :style="{ display: displaySessionTime }">

                                        <span class="metric-tooltips">

                                            Active Time(mins)

                                        </span>
                                        <svg class="sort-arrow" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 11.41 7.12">
                                            <polyline points="10.71 0.71 5.71 5.71 0.71 0.71" fill="none" stroke="#fff"
                                                stroke-miterlimit="10" stroke-width="2"></polyline>
                                        </svg>
                                    </th>
                                    <th v-bind:class="{
                                        sortedAsc: sortedColumn === 1 && sortOrder === 1,
                                        sortedDesc: sortedColumn === 1 && sortOrder === 2,
                                    }" @click="sortByColumnToggle(60)" :style="{ display: displayMatchTime }">

                                        <span class="metric-tooltips">

                                            Match Time (mins)

                                        </span>
                                        <svg class="sort-arrow" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 11.41 7.12">
                                            <polyline points="10.71 0.71 5.71 5.71 0.71 0.71" fill="none" stroke="#fff"
                                                stroke-miterlimit="10" stroke-width="2"></polyline>
                                        </svg>
                                    </th>
                                    <th v-bind:class="{
                                        sortedAsc: sortedColumn === 2 && sortOrder === 1,
                                        sortedDesc: sortedColumn === 2 && sortOrder === 2,
                                    }" @click="sortByColumnToggle(2)">


                                        <span class="metric-tooltips">
                                            Distance (<MeasurementUnit :type="LongDistance" />)
                                            <span class="metric-definition"> Total distance covered</span>
                                        </span>

                                        <svg class="sort-arrow" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 11.41 7.12">
                                            <polyline points="10.71 0.71 5.71 5.71 0.71 0.71" fill="none" stroke="#fff"
                                                stroke-miterlimit="10" stroke-width="2"></polyline>
                                        </svg>
                                    </th>
                                    <th v-bind:class="{
                                        sortedAsc: sortedColumn === 3 && sortOrder === 1,
                                        sortedDesc: sortedColumn === 3 && sortOrder === 2,
                                    }" @click="sortByColumnToggle(3)">


                                        <span class="metric-tooltips">

                                            Peak Speed (<MeasurementUnit :type="PeakSpeed" />)
                                            <span class="metric-definition"> Max speed achieved</span>
                                        </span>
                                        <svg class="sort-arrow" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 11.41 7.12">
                                            <polyline points="10.71 0.71 5.71 5.71 0.71 0.71" fill="none" stroke="#fff"
                                                stroke-miterlimit="10" stroke-width="2"></polyline>
                                        </svg>
                                    </th>
                                    <th v-bind:class="{
                                        sortedAsc: sortedColumn === 4 && sortOrder === 1,
                                        sortedDesc: sortedColumn === 4 && sortOrder === 2,
                                    }" @click="sortByColumnToggle(4)">

                                        <span class="metric-tooltips">
                                            <MeasurementUnit :type="ShortDistance" :unitLong="true" /> Per Min
                                            <span class="metric-definition">Total distance/active time</span>
                                        </span>
                                        <svg class="sort-arrow" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 11.41 7.12">
                                            <polyline points="10.71 0.71 5.71 5.71 0.71 0.71" fill="none" stroke="#fff"
                                                stroke-miterlimit="10" stroke-width="2"></polyline>
                                        </svg>
                                    </th>
                                    <th :style="{ display: displayBBZones }" class="column-separator">|</th>
                                    <th :style="{ display: displayBBZones }" 
                                        v-bind:class="{
                                        sortedAsc: sortedColumn === 61 && sortOrder === 1,
                                        sortedDesc: sortedColumn === 61 && sortOrder === 2,
                                    }" @click="sortByColumnToggle(61)">
                                        <span class="metric-tooltips">
                                            Walking (<MeasurementUnit :type="ShortDistance" />)
                                            <span class="metric-definition">Total distance travelled at a speed &gt; <MeasurementValue :type="Speed" :value=0.01 /><MeasurementUnit :type="Speed" /> and &lt; <MeasurementValue :type="Speed" :value=1.4 /><MeasurementUnit :type="Speed" /> </span>
                                        </span>
                                        <svg class="sort-arrow" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg"
                                             viewBox="0 0 11.41 7.12">
                                            <polyline points="10.71 0.71 5.71 5.71 0.71 0.71" fill="none" stroke="#fff"
                                                      stroke-miterlimit="10" stroke-width="2"></polyline>
                                        </svg>
                                    </th>
                                    <th :style="{ display: displayBBZones }"
                                        v-bind:class="{
                                        sortedAsc: sortedColumn === 62 && sortOrder === 1,
                                        sortedDesc: sortedColumn === 62 && sortOrder === 2,
                                    }" @click="sortByColumnToggle(62)">
                                        <span class="metric-tooltips">
                                            Jogging (<MeasurementUnit :type="ShortDistance" />)
                                            <span class="metric-definition">Total distance travelled at a speed &gt; <MeasurementValue :type="Speed" :value=1.4 /><MeasurementUnit :type="Speed" /> and &lt; <MeasurementValue :type="Speed" :value=2.8 /><MeasurementUnit :type="Speed" /> </span>
                                        </span>
                                        <svg class="sort-arrow" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg"
                                             viewBox="0 0 11.41 7.12">
                                            <polyline points="10.71 0.71 5.71 5.71 0.71 0.71" fill="none" stroke="#fff"
                                                      stroke-miterlimit="10" stroke-width="2"></polyline>
                                        </svg>
                                    </th>
                                    <th :style="{ display: displayBBZones }"
                                        v-bind:class="{
                                        sortedAsc: sortedColumn === 63 && sortOrder === 1,
                                        sortedDesc: sortedColumn === 63 && sortOrder === 2,
                                    }" @click="sortByColumnToggle(63)">
                                        <span class="metric-tooltips">
                                            Running (<MeasurementUnit :type="ShortDistance" />)
                                            <span class="metric-definition">Total distance travelled at a speed &gt; <MeasurementValue :type="Speed" :value=2.8 /><MeasurementUnit :type="Speed" /> and &lt; <MeasurementValue :type="Speed" :value=5.2 /><MeasurementUnit :type="Speed" /> </span>
                                        </span>
                                        <svg class="sort-arrow" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg"
                                             viewBox="0 0 11.41 7.12">
                                            <polyline points="10.71 0.71 5.71 5.71 0.71 0.71" fill="none" stroke="#fff"
                                                      stroke-miterlimit="10" stroke-width="2"></polyline>
                                        </svg>
                                    </th>
                                    <th :style="{ display: displayBBZones }"
                                        v-bind:class="{
                                        sortedAsc: sortedColumn === 64 && sortOrder === 1,
                                        sortedDesc: sortedColumn === 64 && sortOrder === 2,
                                    }" @click="sortByColumnToggle(64)">
                                        <span class="metric-tooltips">
                                            High Speed Running (<MeasurementUnit :type="ShortDistance" />)
                                            <span class="metric-definition">Total distance travelled at a speed &gt; <MeasurementValue :type="Speed" :value=5.2 /><MeasurementUnit :type="Speed" /> and &lt; <MeasurementValue :type="Speed" :value=6.6 /><MeasurementUnit :type="Speed" /> </span>
                                        </span>
                                        <svg class="sort-arrow" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg"
                                             viewBox="0 0 11.41 7.12">
                                            <polyline points="10.71 0.71 5.71 5.71 0.71 0.71" fill="none" stroke="#fff"
                                                      stroke-miterlimit="10" stroke-width="2"></polyline>
                                        </svg>
                                    </th>
                                    <th :style="{ display: displayBBZones }"
                                        v-bind:class="{
                                        sortedAsc: sortedColumn === 65 && sortOrder === 1,
                                        sortedDesc: sortedColumn === 65 && sortOrder === 2,
                                    }" @click="sortByColumnToggle(65)">
                                        <span class="metric-tooltips">
                                            Sprinting (<MeasurementUnit :type="ShortDistance" />)
                                            <span class="metric-definition">Total distance travelled at a speed &gt; <MeasurementValue :type="Speed" :value=6.6 /><MeasurementUnit :type="Speed" /></span>
                                        </span>
                                        <svg class="sort-arrow" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg"
                                             viewBox="0 0 11.41 7.12">
                                            <polyline points="10.71 0.71 5.71 5.71 0.71 0.71" fill="none" stroke="#fff"
                                                      stroke-miterlimit="10" stroke-width="2"></polyline>
                                        </svg>
                                    </th>
                                    <th class="column-separator">|</th>
                                    <th v-bind:class="{
                                        sortedAsc: sortedColumn === 5 && sortOrder === 1,
                                        sortedDesc: sortedColumn === 5 && sortOrder === 2,
                                    }" @click="sortByColumnToggle(5)">

                                        <span class="metric-tooltips">

                                            HSR (<MeasurementUnit :type="ShortDistance" />)
                                            <span class="metric-definition"> Distance covered above <MeasurementValue :type="Speed" :value=5.5 /><MeasurementUnit :type="Speed" /></span>
                                        </span>
                                        <svg class="sort-arrow" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 11.41 7.12">
                                            <polyline points="10.71 0.71 5.71 5.71 0.71 0.71" fill="none" stroke="#fff"
                                                stroke-miterlimit="10" stroke-width="2"></polyline>
                                        </svg>
                                    </th>
                                    <th v-bind:class="{
                                        sortedAsc: sortedColumn === 6 && sortOrder === 1,
                                        sortedDesc: sortedColumn === 6 && sortOrder === 2,
                                    }" @click="sortByColumnToggle(6)">

                                        <span class="metric-tooltips">

                                            Zone 5 (<MeasurementUnit :type="ShortDistance" />)
                                            <span class="metric-definition"> Distance covered between <MeasurementValue :type="Speed" :value=5.5 /><MeasurementUnit :type="Speed" /> &amp;
                                                <MeasurementValue :type="Speed" :value=7 /><MeasurementUnit :type="Speed" /></span>
                                        </span>
                                        <svg class="sort-arrow" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 11.41 7.12">
                                            <polyline points="10.71 0.71 5.71 5.71 0.71 0.71" fill="none" stroke="#fff"
                                                stroke-miterlimit="10" stroke-width="2"></polyline>
                                        </svg>
                                    </th>
                                    <th v-bind:class="{
                                        sortedAsc: sortedColumn === 7 && sortOrder === 1,
                                        sortedDesc: sortedColumn === 7 && sortOrder === 2,
                                    }" @click="sortByColumnToggle(7)">

                                        <span class="metric-tooltips">

                                            Sprint (<MeasurementUnit :type="ShortDistance" />)
                                            <span class="metric-definition"> Distance covered above <MeasurementValue :type="Speed" :value=7 /><MeasurementUnit :type="Speed" /></span>
                                        </span>
                                        <svg class="sort-arrow" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 11.41 7.12">
                                            <polyline points="10.71 0.71 5.71 5.71 0.71 0.71" fill="none" stroke="#fff"
                                                stroke-miterlimit="10" stroke-width="2"></polyline>
                                        </svg>
                                    </th>
                                    <th v-bind:class="{
                                        sortedAsc: sortedColumn === 8 && sortOrder === 1,
                                        sortedDesc: sortedColumn === 8 && sortOrder === 2,
                                    }" @click="sortByColumnToggle(8)">

                                        <span class="metric-tooltips">

                                            High Power (<MeasurementUnit :type="ShortDistance" />)
                                            <span class="metric-definition"> Distance covered above 25W/kg (metabolic
                                                power)</span>
                                        </span>
                                        <svg class="sort-arrow" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 11.41 7.12">
                                            <polyline points="10.71 0.71 5.71 5.71 0.71 0.71" fill="none" stroke="#fff"
                                                stroke-miterlimit="10" stroke-width="2"></polyline>
                                        </svg>
                                    </th>
                                    <th v-bind:class="{
                                        sortedAsc: sortedColumn === 9 && sortOrder === 1,
                                        sortedDesc: sortedColumn === 9 && sortOrder === 2,
                                    }" @click="sortByColumnToggle(9)">

                                        <span class="metric-tooltips">

                                            Explosive (<MeasurementUnit :type="ShortDistance" />)
                                            <span class="metric-definition"> High Power - HSR</span>
                                        </span>
                                        <svg class="sort-arrow" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 11.41 7.12">
                                            <polyline points="10.71 0.71 5.71 5.71 0.71 0.71" fill="none" stroke="#fff"
                                                stroke-miterlimit="10" stroke-width="2"></polyline>
                                        </svg>
                                    </th>
                                    <th class="column-separator">|</th>
                                    <th v-bind:class="{
                                        sortedAsc: sortedColumn === 10 && sortOrder === 1,
                                        sortedDesc: sortedColumn === 10 && sortOrder === 2,
                                    }" @click="sortByColumnToggle(10)">

                                        <span class="metric-tooltips">

                                            Low
                                            <span class="metric-definition"> Number of decelerations with a peak value between
                                                <MeasurementValue :type="Acceleration" :value=2 :format="formatCoarse" /><MeasurementUnit :type="Acceleration" /> &amp; <MeasurementValue :type="Acceleration" :value=4 :format="formatCoarse" /><MeasurementUnit :type="Acceleration" /></span>
                                        </span>
                                        <svg class="sort-arrow" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 11.41 7.12">
                                            <polyline points="10.71 0.71 5.71 5.71 0.71 0.71" fill="none" stroke="#fff"
                                                stroke-miterlimit="10" stroke-width="2"></polyline>
                                        </svg>
                                    </th>
                                    <th v-bind:class="{
                                        sortedAsc: sortedColumn === 11 && sortOrder === 1,
                                        sortedDesc: sortedColumn === 11 && sortOrder === 2,
                                    }" @click="sortByColumnToggle(11)">
                                        <span class="metric-tooltips">

                                            Medium
                                            <span class="metric-definition"> Number of decelerations with a peak value between
                                                <MeasurementValue :type="Acceleration" :value=4 :format="formatCoarse" /><MeasurementUnit :type="Acceleration" />&amp; <MeasurementValue :type="Acceleration" :value=5 :format="formatCoarse" /><MeasurementUnit :type="Acceleration" /></span>
                                        </span>
                                        <svg class="sort-arrow" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 11.41 7.12">
                                            <polyline points="10.71 0.71 5.71 5.71 0.71 0.71" fill="none" stroke="#fff"
                                                stroke-miterlimit="10" stroke-width="2"></polyline>
                                        </svg>
                                    </th>
                                    <th v-bind:class="{
                                        sortedAsc: sortedColumn === 12 && sortOrder === 1,
                                        sortedDesc: sortedColumn === 12 && sortOrder === 2,
                                    }" @click="sortByColumnToggle(12)">
                                        <span class="metric-tooltips">

                                            High
                                            <span class="metric-definition"> Number of decelerations with a peak value above
                                                <MeasurementValue :type="Acceleration" :value=5 :format="formatCoarse" /><MeasurementUnit :type="Acceleration" /></span>
                                        </span>
                                        <svg class="sort-arrow" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 11.41 7.12">
                                            <polyline points="10.71 0.71 5.71 5.71 0.71 0.71" fill="none" stroke="#fff"
                                                stroke-miterlimit="10" stroke-width="2"></polyline>
                                        </svg>
                                    </th>
                                    <th class="column-separator">|</th>
                                    <th v-bind:class="{
                                        sortedAsc: sortedColumn === 13 && sortOrder === 1,
                                        sortedDesc: sortedColumn === 13 && sortOrder === 2,
                                    }" @click="sortByColumnToggle(13)">
                                        <span class="metric-tooltips">

                                            Low
                                            <span class="metric-definition"> Number of accelerations with a peak value between
                                                <MeasurementValue :type="Acceleration" :value=2 :format="formatCoarse" /><MeasurementUnit :type="Acceleration" />&amp; <MeasurementValue :type="Acceleration" :value=4 :format="formatCoarse" /><MeasurementUnit :type="Acceleration" /></span>
                                        </span>
                                        <svg class="sort-arrow" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 11.41 7.12">
                                            <polyline points="10.71 0.71 5.71 5.71 0.71 0.71" fill="none" stroke="#fff"
                                                stroke-miterlimit="10" stroke-width="2"></polyline>
                                        </svg>
                                    </th>
                                    <th v-bind:class="{
                                        sortedAsc: sortedColumn === 14 && sortOrder === 1,
                                        sortedDesc: sortedColumn === 14 && sortOrder === 2,
                                    }" @click="sortByColumnToggle(14)">
                                        <span class="metric-tooltips">

                                            Medium
                                            <span class="metric-definition"> Number of accelerations with a peak value between
                                                <MeasurementValue :type="Acceleration" :value=4 :format="formatCoarse" /><MeasurementUnit :type="Acceleration" />&amp; <MeasurementValue :type="Acceleration" :value=5 :format="formatCoarse"/><MeasurementUnit :type="Acceleration" /></span>
                                        </span>
                                        <svg class="sort-arrow" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 11.41 7.12">
                                            <polyline points="10.71 0.71 5.71 5.71 0.71 0.71" fill="none" stroke="#fff"
                                                stroke-miterlimit="10" stroke-width="2"></polyline>
                                        </svg>
                                    </th>
                                    <th v-bind:class="{
                                        sortedAsc: sortedColumn === 15 && sortOrder === 1,
                                        sortedDesc: sortedColumn === 15 && sortOrder === 2,
                                    }" @click="sortByColumnToggle(15)">
                                        <span class="metric-tooltips">

                                            High
                                            <span class="metric-definition">Number of accelerations with a peak value above
                                                <MeasurementValue :type="Acceleration" :value=5 :format="formatCoarse" /><MeasurementUnit :type="Acceleration" /></span>
                                        </span>
                                        <svg class="sort-arrow" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 11.41 7.12">
                                            <polyline points="10.71 0.71 5.71 5.71 0.71 0.71" fill="none" stroke="#fff"
                                                stroke-miterlimit="10" stroke-width="2"></polyline>
                                        </svg>
                                    </th>
                                    <th class="column-separator">|</th>
                                    <th v-bind:class="{
                                        sortedAsc: sortedColumn === 16 && sortOrder === 1,
                                        sortedDesc: sortedColumn === 16 && sortOrder === 2,
                                    }" @click="sortByColumnToggle(16)">
                                        <span class="metric-tooltips">

                                            Low
                                            <span class="metric-definition"> Distance covered between <MeasurementValue :type="Acceleration" :value=2 :format="formatCoarse" /><MeasurementUnit :type="Acceleration" />
                                                &amp; <MeasurementValue :type="Acceleration" :value=4 :format="formatCoarse"/><MeasurementUnit :type="Acceleration" /></span>
                                        </span>
                                        <svg class="sort-arrow" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 11.41 7.12">
                                            <polyline points="10.71 0.71 5.71 5.71 0.71 0.71" fill="none" stroke="#fff"
                                                stroke-miterlimit="10" stroke-width="2"></polyline>
                                        </svg>
                                    </th>
                                    <th v-bind:class="{
                                        sortedAsc: sortedColumn === 17 && sortOrder === 1,
                                        sortedDesc: sortedColumn === 17 && sortOrder === 2,
                                    }" @click="sortByColumnToggle(17)">
                                        <span class="metric-tooltips">

                                            Medium
                                            <span class="metric-definition"> Distance covered between <MeasurementValue :type="Acceleration" :value=4 :format="formatCoarse"/><MeasurementUnit :type="Acceleration" />
                                                &amp; <MeasurementValue :type="Acceleration" :value=5 :format="formatCoarse"/><MeasurementUnit :type="Acceleration" /></span>
                                        </span>
                                        <svg class="sort-arrow" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 11.41 7.12">
                                            <polyline points="10.71 0.71 5.71 5.71 0.71 0.71" fill="none" stroke="#fff"
                                                stroke-miterlimit="10" stroke-width="2"></polyline>
                                        </svg>
                                    </th>
                                    <th v-bind:class="{
                                        sortedAsc: sortedColumn === 18 && sortOrder === 1,
                                        sortedDesc: sortedColumn === 18 && sortOrder === 2,
                                    }" @click="sortByColumnToggle(18)">
                                        <span class="metric-tooltips">

                                            High
                                            <span class="metric-definition"> Distance covered above
                                                <MeasurementValue :type="Acceleration" :value=5 :format="formatCoarse"/><MeasurementUnit :type="Acceleration" /></span>
                                        </span>
                                        <svg class="sort-arrow" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 11.41 7.12">
                                            <polyline points="10.71 0.71 5.71 5.71 0.71 0.71" fill="none" stroke="#fff"
                                                stroke-miterlimit="10" stroke-width="2"></polyline>
                                        </svg>
                                    </th>
                                    <th :style="{ display: accelsDecelsDisplay }" class="column-separator">|</th>

                                    <th :style="{ display: accelsDecelsDisplay }" v-bind:class="{
                                        sortedAsc: sortedColumn === 54 && sortOrder === 1,
                                        sortedDesc: sortedColumn === 54 && sortOrder === 2,
                                    }" @click="sortByColumnToggle(54)">
                                        <span class="metric-tooltips">

                                            <MeasurementValue :type="Acceleration" :value=3 :format="formatCoarse"/><MeasurementUnit :type="Acceleration" />Accelerations
                                            <span class="metric-definition"> Number of accelerations above
                                                <MeasurementValue :type="Acceleration" :value=3 :format="formatCoarse"/><MeasurementUnit :type="Acceleration" /></span>
                                        </span>
                                        <svg class="sort-arrow" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 11.41 7.12">
                                            <polyline points="10.71 0.71 5.71 5.71 0.71 0.71" fill="none" stroke="#fff"
                                                stroke-miterlimit="10" stroke-width="2"></polyline>
                                        </svg>
                                    </th>

                                    <th :style="{ display: accelsDecelsDisplay }" v-bind:class="{
                                        sortedAsc: sortedColumn === 55 && sortOrder === 1,
                                        sortedDesc: sortedColumn === 55 && sortOrder === 2,
                                    }" @click="sortByColumnToggle(55)">
                                        <span class="metric-tooltips">
                                            <MeasurementValue :type="Acceleration" :value=3 :format="formatCoarse" /><MeasurementUnit :type="Acceleration" /> Decelerations
                                            <span class="metric-definition"> Number of decelerations above
                                                <MeasurementValue :type="Acceleration" :value=3 :format="formatCoarse"/><MeasurementUnit :type="Acceleration" /></span>
                                        </span>
                                        <svg class="sort-arrow" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 11.41 7.12">
                                            <polyline points="10.71 0.71 5.71 5.71 0.71 0.71" fill="none" stroke="#fff"
                                                stroke-miterlimit="10" stroke-width="2"></polyline>
                                        </svg>
                                    </th>
                                    <th class="fixed-column-right"></th>
                                </tr>
                            </thead>
                            <tbody v-for="(kpi, k) in sessionData" v-bind:key="kpi.playerId" style="text-align:center">
                                <tr class="position-header"
                                    v-if="groupByPosition && ((k > 0 && sessionData[k - 1].position !== kpi.position) || k === 0)">
                                    <td class="fixed-column-left" v-bind:class="{ 'has-shadow': !tableScrolledLeft }">
                                        {{ kpi.position }}
                                    </td>
                                    <td colspan="22"></td>
                                    <td class="fixed-column-right" v-bind:class="{ 'has-shadow': !tableScrolledRight }">
                                    </td>
                                </tr>
                                <tr>
                                    <td class="fixed-column-left" v-bind:class="{ 'has-shadow': !tableScrolledLeft }">
                                        <div class="avatar-wrapper" style="align-items:center">
                                            {{ kpi.player }}
                                            <EditPlayer v-if="isIdentified(kpi.playerId)" :player = "kpi" :refreshData="refreshData" />
                                            <IdentifyPlayer v-if="!isIdentified(kpi.playerId)" 
                                                 :customerId="customerId" :sessionId="sessionId"
                                                 :playerThumbnail="getUnidentifiedThumbnail(kpi.playerId)" :unusedPlayers="unusedPlayers"
                                                 :onSaveSuccess="() => {getKpiData(); getUnidentifiedPlayers()}"/>
                                            <div class="avatar-description" style="padding-left:10px">
                                                <h5 class="color-text-primary"
                                                    style="color: white;">
                                                    {{ kpi.name }}
                                                </h5>
                                                <h3 class="h6 color-text-secondary avatar-position">
                                                    <template v-if="useShirtNumber">
                                                        <ShirtNumberDisplay :shirtNumber="kpi.shirtNumber"/>
                                                    </template>
                                                    <template v-else>
                                                        {{ kpi.position }}
                                                    </template>
                                                </h3>
                                            </div>
                                        </div>
                                    </td>
                                    <td :style="{ display: displaySessionTime }"> {{ kpi[columnProperties[0]] === null ? '-'
                                        : kpi[columnProperties[0]].toFixed(1)
                                    }}</td>
                                    <td :style="{ display: displaySessionTime }">{{ kpi[columnProperties[1]] === null ? '-'
                                        : kpi[columnProperties[1]].toFixed(1)
                                    }}</td>
                                    <td :style="{ display: displayMatchTime }">{{ kpi[columnProperties[60]] }}</td>
                                    <td :class="kpi[columnProperties[37]]">
                                      <MeasurementValue :type="LongDistance" :value="kpi[columnProperties[2]]" nullString="-" />
                                      <span> {{ kpi[columnProperties[20]]}}</span>
                                    </td>
                                    <td style="text-align:center" :class="kpi[columnProperties[38]]">
                                        <MeasurementValue :type="PeakSpeed" :value="kpi[columnProperties[3]]" nullString="-" />
                                        <span class="text-success float-right">({{ calcSpeedVsPersonalBest(kpi) }}%) {{
                                                kpi[columnProperties[21]]
                                        }}</span>
                                    </td>
                                    <td :class="kpi[columnProperties[39]]">
                                      <MeasurementValue :type="ShortDistance" :value="kpi[columnProperties[4]]" nullString="-" />
                                      <span>{{ kpi[columnProperties[22]]}}</span>
                                    </td>
                                    <td :style="{ display: displayBBZones }" class="column-separator"></td>
                                    <td :style="{ display: displayBBZones }" :class="kpi[columnProperties[71]]">
                                        <MeasurementValue :type="ShortDistance" :value="kpi[columnProperties[61]]" nullString="-" />
                                        <span> {{ kpi[columnProperties[66]]}}</span>
                                    </td>
                                    <td :style="{ display: displayBBZones }" :class="kpi[columnProperties[72]]">
                                        <MeasurementValue :type="ShortDistance" :value="kpi[columnProperties[62]]" nullString="-" />
                                        <span> {{ kpi[columnProperties[67]]}}</span>
                                    </td>
                                    <td :style="{ display: displayBBZones }" :class="kpi[columnProperties[73]]">
                                        <MeasurementValue :type="ShortDistance" :value="kpi[columnProperties[63]]" nullString="-" />
                                        <span> {{ kpi[columnProperties[68]]}}</span>
                                    </td>
                                    <td :style="{ display: displayBBZones }" :class="kpi[columnProperties[74]]">
                                        <MeasurementValue :type="ShortDistance" :value="kpi[columnProperties[64]]" nullString="-" />
                                        <span> {{ kpi[columnProperties[69]]}}</span>
                                    </td>
                                    <td :style="{ display: displayBBZones }" :class="kpi[columnProperties[75]]">
                                        <MeasurementValue :type="ShortDistance" :value="kpi[columnProperties[65]]" nullString="-" />
                                        <span> {{ kpi[columnProperties[70]]}}</span>
                                    </td>
                                    <td class="column-separator"></td>
                                    <td :class="kpi[columnProperties[40]]">
                                      <MeasurementValue :type="ShortDistance" :value="kpi[columnProperties[5]]" nullString="-" />
                                      <span> {{ kpi[columnProperties[23]]}}</span>
                                    </td>
                                    <td :class="kpi[columnProperties[41]]">
                                      <MeasurementValue :type="ShortDistance" :value="kpi[columnProperties[6]]" nullString="-" /> 
                                      <span> {{ kpi[columnProperties[24]] }}</span>
                                    </td>
                                    <td :class="kpi[columnProperties[42]]">
                                      <MeasurementValue :type="ShortDistance" :value="kpi[columnProperties[7]]" nullString="-" />
                                      <span> {{ kpi[columnProperties[25]]}}</span>
                                    </td>
                                    <td :class="kpi[columnProperties[43]]">
                                      <MeasurementValue :type="ShortDistance" :value="kpi[columnProperties[8]]" nullString="-" />
                                        <span> {{ kpi[columnProperties[26]] }}</span>
                                    </td>
                                    <td :class="kpi[columnProperties[44]]">
                                      <MeasurementValue :type="ShortDistance" :value="kpi[columnProperties[9]]" nullString="-" />
                                      <span> {{ kpi[columnProperties[27]]}}</span>
                                    </td>
                                    <td class="column-separator"></td>
                                    <td :class="kpi[columnProperties[45]]">{{ kpi[columnProperties[10]] === null ? '-' :
                                        kpi[columnProperties[10]]
                                    }} <span> {{ kpi[columnProperties[28]] }}</span></td>
                                    <td :class="kpi[columnProperties[46]]">{{ kpi[columnProperties[11]] === null ? '-' :
                                        kpi[columnProperties[11]]
                                    }} <span> {{ kpi[columnProperties[29]] }}</span></td>
                                    <td :class="kpi[columnProperties[47]]">{{ kpi[columnProperties[12]] === null ? '-' :
                                        kpi[columnProperties[12]]
                                    }} <span> {{ kpi[columnProperties[30]] }}</span></td>
                                    <td class="column-separator"></td>
                                    <td :class="kpi[columnProperties[48]]">{{ kpi[columnProperties[13]] === null ? '-' :
                                        kpi[columnProperties[13]]
                                    }} <span> {{ kpi[columnProperties[31]] }}</span></td>
                                    <td :class="kpi[columnProperties[49]]">{{ kpi[columnProperties[14]] === null ? '-' :
                                        kpi[columnProperties[14]]
                                    }} <span> {{ kpi[columnProperties[32]] }}</span></td>
                                    <td :class="kpi[columnProperties[50]]">{{ kpi[columnProperties[15]] === null ? '-' :
                                        kpi[columnProperties[15]]
                                    }} <span> {{ kpi[columnProperties[33]] }}</span></td>
                                    <td class="column-separator"></td>
                                    <td :class="kpi[columnProperties[51]]">
                                      <MeasurementValue :type="ShortDistance" :value="kpi[columnProperties[16]]" nullString="-" />
                                      <span> {{ kpi[columnProperties[34]]}}</span>
                                    </td>
                                    <td :class="kpi[columnProperties[52]]">
                                      <MeasurementValue :type="ShortDistance" :value="kpi[columnProperties[17]]" nullString="-" />
                                      <span> {{ kpi[columnProperties[35]]}}</span>
                                    </td>
                                    <td :class="kpi[columnProperties[53]]">
                                      <MeasurementValue :type="ShortDistance" :value="kpi[columnProperties[18]]" nullString="-" />
                                      <span> {{ kpi[columnProperties[36]]}}</span>
                                    </td>
                                    <td :style="{ display: accelsDecelsDisplay }" class="column-separator"></td>
                                    <td :style="{ display: accelsDecelsDisplay }" :class="kpi[columnProperties[56]]">{{
                                        kpi[columnProperties[54]] === null ? '-' : kpi[columnProperties[54]]
                                    }} <span> {{
    kpi[columnProperties[58]]
}}</span></td>
                                    <td :style="{ display: accelsDecelsDisplay }" :class="kpi[columnProperties[57]]">{{
                                        kpi[columnProperties[55]] === null ? '-' : kpi[columnProperties[55]]}} <span> {{
    kpi[columnProperties[59]]
}}</span></td>

                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import { errorHandler } from "@/components/ErrorHandler";
import { UserData } from "@/components/UserData";
import UnidentifiedPlayers from "./UnidentifiedPlayers.vue";
import DateUtils from "@/utils/DateUtils";
import download from "@/utils/Download";

import {
    getMeasurementUnit,
    MeasurementTypes,
    MeasurementUnit as MU
} from "@/utils/MeasurementSystem";
import UpdateSetting from "@/utils/UpdateSetting";

export default {
    components: {
        UnidentifiedPlayers,
    },
    data() {
        return {
            formatCoarse: MU.prototype.formatCoarse,
            Acceleration: MeasurementTypes.Acceleration,
            Speed: MeasurementTypes.Speed,
            PeakSpeed: MeasurementTypes.PeakSpeed,
            ShortDistance: MeasurementTypes.ShortDistance,
            LongDistance: MeasurementTypes.LongDistance,
            session: null,
            sessionData: [],
            sessionId: "",
            sessionType: "",
            sessionTypeColour: "",
            sessionTitle: "",
            pbPeakSpeed1: [],
            fieldDefinition: [
                {
                    key: "playerId",
                    thClass: "d-none",
                    tdClass: "d-none"
                },
                {
                    key: "name",
                    label: "Player",
                    sortable: true,
                    stickyColumn: true
                },
                { key: "position", label: "Position", sortable: true },
                {
                    key: "sessionTotalTimeM",
                    label: "Session Time(mins)",
                    sortable: true
                }
            ],
            isBusy: false,
            currentPage: 1,
            perPage: 20,
            tableScrolledLeft: true,
            tableScrolledRight: false,
            columnProperties: [
                "sessionTotalTimeM",
                "activeTimeM",
                "activeDistanceM",
                "peakSpeedMPerS",
                "averageSpeedMPerMinute",
                "highSpeedDistanceM",
                "zone5DistanceM",
                "sprintDistanceM",
                "highPowerDistanceM",
                "explosiveDistanceM",
                "lowDecelerations",
                "mediumDecelerations",
                "highDecelerations",
                "lowAccelerations",
                "mediumAccelerations",
                "highAccelerations",
                "lowAccelerationDistanceM",
                "mediumAccelerationDistanceM",
                "highAccelerationDistanceM",
                "peakSpeedPersonalBestMPerS",
                "pbActiveDistanceM",
                "pbPeakSpeedMPerS",
                "pbAverageSpeedMPerMinute",
                "pbHighSpeedDistanceM",
                "pbZone5DistanceM",
                "pbSprintDistanceM",
                "pbHighPowerDistanceM",
                "pbExplosiveDistanceM",
                "pbLowDecelerations",
                "pbMediumDecelerations",
                "pbHighDecelerations",
                "pbLowAccelerations",
                "pbMediumAccelerations",
                "pbHighAccelerations",
                "pbLowAccelerationDistanceM",
                "pbMediumAccelerationDistanceM",
                "pbHighAccelerationDistanceM",
                "distanceClass",
                "peakSpeedClass",
                "averageSpeedClass",
                "hsrClass",
                "zone5Class",
                "sprintDistanceClass",
                "highPowerClass",
                "explosiveDistanceClass",
                "lowDecelerationsClass",
                "mediumDecelerationsClass",
                "highDecelerationsClass",
                "lowAccelerationsClass",
                "mediumAccelerationsClass",
                "highAccelerationsClass",
                "lowAccelerationDistanceClass",
                "mediumAccelerationDistanceClass",
                "highAccelerationDistanceClass",
                "accelerations",
                "decelerations",
                "accelerationsClass",
                "decelerationsClass",
                "pbAccelerations",
                "pbDecelerations",
                "matchTimeM",
                "bbZone2DistanceM", // 61
                "bbZone3DistanceM",
                "bbZone4DistanceM",
                "bbZone5DistanceM",
                "bbZone6DistanceM",
                "pb_bbZone2DistanceM", // 66
                "pb_bbZone3DistanceM",
                "pb_bbZone4DistanceM",
                "pb_bbZone5DistanceM",
                "pb_bbZone6DistanceM",
                "bbZone2DistanceMClass", // 71
                "bbZone3DistanceMClass",
                "bbZone4DistanceMClass",
                "bbZone5DistanceMClass",
                "bbZone6DistanceMClass"
            ],
            groupByPosition: false,
            sortedColumn: 0,
            sortOrder: 0,
            playerId: null,
            positionId: null,
            positionData: [],
            isWIP: UserData.isWIP(),
            hasExportData: UserData.hasExportData(),
            hasOppositionAnalysis: UserData.hasOppositionAnalysis(),
            peakSpeedUnit: localStorage.getItem('peakSpeedUnit'),
            accelsDecelsDisplay: null,
            hasOpposition: null,
            displayMatchTime: '',
            displaySessionTime: '',
            displayBBZones: (UserData.displayBBSpeedZones() ? '' : 'none'),
            customerId: UserData.customerId(),
            unidentifiedThumbnails: [],
            unusedPlayers: [],
            useShirtNumber: UserData.useShirtNumber(),
            matchObservations: null,
            hasMatchObservationsData: false,
        };
    },
    methods: {
        navigateTo(newPage) {
            this.$router.push(newPage).catch(() => { });
        },
        async refreshData() {
            // get query string here
            this.sessionId = this.$route.params.id;
            await this.getSession(); // get session info
            await this.getKpiData(); // grab kpi for the session
            await this.getUnidentifiedPlayers(); // get details of any unidentified players in the session
            await this.getAutomatedMatchObservations();
            this.sortOrder = 0;
        },

        displayUnidentifiedPlayersModal() {
            if (this.unidentifiedThumbnails) {
                this.$bvModal.show('player-identification-edit-modal');
            }
        },
        isIdentified(playerId) {
            return !this.getUnidentifiedThumbnail(playerId);
        },
        getUnidentifiedThumbnail(playerId) {
            return this.unidentifiedThumbnails.find(t => t.playerId === playerId);
        },

        async getUnidentifiedPlayers() {
            const response = await this.$root.webApiGet(
                `/unidentifiedPlayers?customerId=${this.customerId}&sessionId=${this.sessionId}`
            );
            if (response.status === 200) {
                this.unidentifiedThumbnails = response.data.unidentifiedThumbnails;
                this.unusedPlayers = response.data.unusedPlayers;
            } else {
                errorHandler.error(response, this);
            }
        },
        async getAutomatedMatchObservations() {
            const response = await this.$root.webApiGet(
                `/automatedmatchobservations?customerId=${this.customerId}&sessionId=${this.sessionId}`
            );
            if (response.status === 200) {
                const matchObservations = response.data.matchObservations;
                this.hasMatchObservationsData = matchObservations.length > 0;
                if (this.hasMatchObservationsData) {
                    this.matchObservations = matchObservations;
                }
            } else {
                errorHandler.error(response, this);
            }
        },
        async getKpiData() {
            // get the kpi data
            this.sessionData = [];

            const response = await this.$root.webApiGet(
                `/kpis?customerId=${this.customerId}&sessionId=${this.sessionId}&enablePerMinute=false`
            );

            if (response.status === 200) {
                const kpis = response.data;
                const positionComparator = (a,b) => a.player.positionSortOrder - b.player.positionSortOrder;
                const shirtNumberComparator = (a,b) => {
                    const shirtNumberA = a.player.shirtNumber;
                    const shirtNumberB = b.player.shirtNumber;
                    // Move players with no shirt number to the bottom
                    if (shirtNumberA === null || shirtNumberA === undefined) return 1;
                    if (shirtNumberB === null || shirtNumberB === undefined) return -1;
                    // Sort by shirt number in ascending order
                    return shirtNumberA - shirtNumberB;
                };

                const data = kpis.sort(this.useShirtNumber ? shirtNumberComparator : positionComparator);
                const star = "\u2B50";
                const accelsDecels = [];
                for (let i = 0;i < data.length;i++) {
                    const activeTime = data[i].activeTimeM;
                    const kpis = data[i];
                    const matchTime = kpis.matchTimeM;
                    const hasMatchTime = matchTime !== null;
                    this.displayMatchTime = hasMatchTime === true ? '' : 'none';
                    this.displaySessionTime = hasMatchTime !== true ? '' : 'none';

                    const isPersonalBest = kpi => {
                        const value = kpi.value;
                        const max = kpi.maximum;
                        return value !== null && value >= max  ? star : '';
                    };
                    const pbPeakSpeedMPerS = isPersonalBest(kpis.peakSpeedMPerS);
                    const pbActiveDistanceM = isPersonalBest(kpis.activeDistanceM);
                    const pbAverageSpeedMPerMinute = isPersonalBest(kpis.averageSpeedMPerMinute);
                    const pbExplosiveDistanceM = isPersonalBest(kpis.explosiveDistanceM);
                    const pbHighAccelerationDistanceM = isPersonalBest(kpis.highAccelerationDistanceM);
                    const pbHighAccelerations = isPersonalBest(kpis.highAccelerations);
                    const pbHighDecelerations = isPersonalBest(kpis.highDecelerations);
                    const pbHighPowerDistanceM = isPersonalBest(kpis.highPowerDistanceM);
                    const pbHighSpeedDistanceM = isPersonalBest(kpis.highSpeedDistanceM);
                    const pbLowAccelerationDistanceM = isPersonalBest(kpis.lowAccelerationDistanceM);
                    const pbLowAccelerations = isPersonalBest(kpis.lowAccelerations);
                    const pbLowDecelerations = isPersonalBest(kpis.lowDecelerations);
                    const pbMediumAccelerationDistanceM = isPersonalBest(kpis.mediumAccelerationDistanceM);
                    const pbMediumAccelerations = isPersonalBest(kpis.mediumAccelerations);
                    const pbMediumDecelerations = isPersonalBest(kpis.mediumDecelerations);
                    const pbSprintDistanceM = isPersonalBest(kpis.sprintDistanceM);
                    const pbZone5DistanceM = isPersonalBest(kpis.zone5DistanceM);
                    const pbAccelerations = isPersonalBest(kpis.accelerations);
                    const pbDecelerations = isPersonalBest(kpis.decelerations);
                    const pbBbZone2DdistanceM = isPersonalBest(kpis.bbZone2DistanceM);
                    const pbBbZone3DdistanceM = isPersonalBest(kpis.bbZone3DistanceM);
                    const pbBbZone4DdistanceM = isPersonalBest(kpis.bbZone4DistanceM);
                    const pbBbZone5DdistanceM = isPersonalBest(kpis.bbZone5DistanceM);
                    const pbBbZone6DdistanceM = isPersonalBest(kpis.bbZone6DistanceM);

                    const tableKpis = {
                        'sessionTotalTimeM': kpis.sessionTotalTimeM, 'activeTimeM': kpis.activeTimeM,
                        'activeDistanceM': kpis.activeDistanceM.value, 'peakSpeedMPerS': kpis.peakSpeedMPerS.value,
                        'averageSpeedMPerMinute': kpis.averageSpeedMPerMinute.value, 'highSpeedDistanceM': kpis.highSpeedDistanceM.value,
                        'zone5DistanceM': kpis.zone5DistanceM.value, 'sprintDistanceM': kpis.sprintDistanceM.value,
                        'highPowerDistanceM': kpis.highPowerDistanceM.value, 'explosiveDistanceM': kpis.explosiveDistanceM.value,
                        'lowDecelerations': kpis.lowDecelerations.value, 'mediumDecelerations': kpis.mediumDecelerations.value,
                        'highDecelerations': kpis.highDecelerations.value, 'lowAccelerations': kpis.lowAccelerations.value,
                        'mediumAccelerations': kpis.mediumAccelerations.value, 'highAccelerations': kpis.highAccelerations.value,
                        'lowAccelerationDistanceM': kpis.lowAccelerationDistanceM.value, 'mediumAccelerationDistanceM': kpis.mediumAccelerationDistanceM.value,
                        'highAccelerationDistanceM': kpis.highAccelerationDistanceM.value, 'peakSpeedPersonalBestMPerS': kpis.peakSpeedMPerS.maximum,
                        'accelerations': kpis.accelerations.value, 'decelerations': kpis.decelerations.value, 'matchTimeM': matchTime,
                        'bbZone2DistanceM': kpis.bbZone2DistanceM.value, 'bbZone3DistanceM': kpis.bbZone3DistanceM.value, 
                        'bbZone4DistanceM': kpis.bbZone4DistanceM.value, 'bbZone5DistanceM': kpis.bbZone5DistanceM.value, 
                        'bbZone6DistanceM': kpis.bbZone6DistanceM.value
                    };

                    const pbKPIs = {
                        'pbPeakSpeedMPerS': pbPeakSpeedMPerS, 'pbActiveDistanceM': pbActiveDistanceM, 'pbAverageSpeedMPerMinute': pbAverageSpeedMPerMinute,
                        'pbExplosiveDistanceM': pbExplosiveDistanceM, 'pbHighAccelerationDistanceM': pbHighAccelerationDistanceM, 'pbHighAccelerations': pbHighAccelerations,
                        'pbHighDecelerations': pbHighDecelerations, 'pbHighPowerDistanceM': pbHighPowerDistanceM, 'pbHighSpeedDistanceM': pbHighSpeedDistanceM,
                        'pbLowAccelerationDistanceM': pbLowAccelerationDistanceM, 'pbLowAccelerations': pbLowAccelerations, 'pbLowDecelerations': pbLowDecelerations,
                        'pbMediumAccelerationDistanceM': pbMediumAccelerationDistanceM, 'pbMediumAccelerations': pbMediumAccelerations, 'pbMediumDecelerations': pbMediumDecelerations,
                        'pbSprintDistanceM': pbSprintDistanceM, 'pbZone5DistanceM': pbZone5DistanceM, 'pbAccelerations': pbAccelerations, 'pbDecelerations': pbDecelerations,
                        'pb_bbZone2DistanceM': pbBbZone2DdistanceM, 'pb_bbZone3DistanceM': pbBbZone3DdistanceM, 'pb_bbZone4DistanceM': pbBbZone4DdistanceM,
                        'pb_bbZone5DistanceM': pbBbZone5DdistanceM, 'pb_bbZone6DistanceM': pbBbZone6DdistanceM,
                    };

                    const getKpiColour = kpi => {
                        const lower = parseFloat(kpi.average) - kpi.standardDeviation;
                        const upper = parseFloat(kpi.average) + kpi.standardDeviation;
                        const colourClass = [];

                        if (kpi.value < lower && activeTime >= 85) {
                            colourClass.push('red');
                        } else if (kpi.value >= lower && kpi.value <= upper || isNaN(lower)) {
                            colourClass.push('amber');
                        } else if (kpi.value > upper) {
                            colourClass.push('green');
                        }
                        return colourClass;
                    };
                    const distanceClass = getKpiColour(kpis.activeDistanceM);
                    const peakSpeedClass = getKpiColour(kpis.peakSpeedMPerS);
                    const averageSpeedClass = getKpiColour(kpis.averageSpeedMPerMinute);
                    const hsrClass = getKpiColour(kpis.highSpeedDistanceM);
                    const zone5Class = getKpiColour(kpis.zone5DistanceM);
                    const sprintDistanceClass = getKpiColour(kpis.sprintDistanceM);
                    const highPowerClass = getKpiColour(kpis.highPowerDistanceM);
                    const explosiveDistanceClass = getKpiColour(kpis.explosiveDistanceM);
                    const lowDecelerationsClass = getKpiColour(kpis.lowDecelerations);
                    const mediumDecelerationsClass = getKpiColour(kpis.mediumDecelerations);
                    const highDecelerationsClass = getKpiColour(kpis.highDecelerations);
                    const lowAccelerationsClass = getKpiColour(kpis.lowAccelerations);
                    const mediumAccelerationsClass = getKpiColour(kpis.mediumAccelerations);
                    const highAccelerationsClass = getKpiColour(kpis.highAccelerations);
                    const lowAccelerationDistanceClass = getKpiColour(kpis.lowAccelerationDistanceM);
                    const mediumAccelerationDistanceClass = getKpiColour(kpis.mediumAccelerationDistanceM);
                    const highAccelerationDistanceClass = getKpiColour(kpis.highAccelerationDistanceM);
                    const decelerationsClass = getKpiColour(kpis.decelerations);
                    const accelerationsClass = getKpiColour(kpis.accelerations);
                    const bbZone2DistanceMClass = getKpiColour(kpis.bbZone2DistanceM);
                    const bbZone3DistanceMClass = getKpiColour(kpis.bbZone3DistanceM);
                    const bbZone4DistanceMClass = getKpiColour(kpis.bbZone4DistanceM);
                    const bbZone5DistanceMClass = getKpiColour(kpis.bbZone5DistanceM);
                    const bbZone6DistanceMClass = getKpiColour(kpis.bbZone6DistanceM);

                    const kpisClasses = {
                        'distanceClass': distanceClass, 'averageSpeedClass': averageSpeedClass, 'peakSpeedClass': peakSpeedClass, 'hsrClass': hsrClass,
                        'zone5Class': zone5Class, 'sprintDistanceClass': sprintDistanceClass, 'highPowerClass': highPowerClass, 'explosiveDistanceClass': explosiveDistanceClass,
                        'lowDecelerationsClass': lowDecelerationsClass, 'mediumDecelerationsClass': mediumDecelerationsClass, 'highDecelerationsClass': highDecelerationsClass,
                        'lowAccelerationsClass': lowAccelerationsClass, 'mediumAccelerationsClass': mediumAccelerationsClass, 'highAccelerationsClass': highAccelerationsClass,
                        'lowAccelerationDistanceClass': lowAccelerationDistanceClass, 'mediumAccelerationDistanceClass': mediumAccelerationDistanceClass, 'highAccelerationDistanceClass': highAccelerationDistanceClass,
                        'accelerationsClass': accelerationsClass, 'decelerationsClass': decelerationsClass,
                        'bbZone2DistanceMClass': bbZone2DistanceMClass, 'bbZone3DistanceMClass': bbZone3DistanceMClass,
                        'bbZone4DistanceMClass': bbZone4DistanceMClass, 'bbZone5DistanceMClass': bbZone5DistanceMClass,
                        'bbZone6DistanceMClass': bbZone6DistanceMClass
                    };

                    accelsDecels.push(tableKpis.accelerations, tableKpis.decelerations);
                    const sessionData = { ...tableKpis, ...kpis.player, ...pbKPIs, ...kpisClasses };
                    this.sessionData.push(sessionData);
                }
                this.accelsDecelsDisplay = accelsDecels.every(element => element === null) === true ? 'none' : ' ';

            } else {
                errorHandler.error(response, this);
            }
        },
        tableScrolled() {
            // determine if the table is scrolled hard left or hard right
            const scrollTable = document.getElementById("session-table");
            this.tableScrolledLeft = scrollTable.scrollLeft === 0;
            this.tableScrolledRight =
                scrollTable.scrollLeft ===
                scrollTable.scrollWidth - scrollTable.clientWidth;
        },
        sortByColumnToggle(column) {
            // toggle the sort order
            if (this.sortedColumn === column) {
                if (this.sortOrder === 1) {
                    this.sortOrder = 2;
                } else {
                    this.sortOrder = 1;
                }
            } else {
                this.sortedColumn = column;
                this.sortOrder = 1;
            }
            this.sort();
        },
        sort() {
            const propertyName = this.columnProperties[this.sortedColumn];
            if (this.sortOrder === 0) {
                // no sort order set, but group by position enabled
                this.sessionData.sort((a, b) =>
                    this.compare(a.player.position, b.player.position)
                );
            } else {
                if (this.groupByPosition) {
                    if (this.sortOrder === 1) {
                        // group by position, then sort descending
                        this.sessionData.sort(
                            (a, b) =>
                                this.compare(
                                    a.player.position,
                                    b.player.position
                                ) ||
                                this.compare(a[propertyName], b[propertyName])
                        );
                    } else if (this.sortOrder === 2) {
                        // group by position, then sort ascending
                        this.sessionData.sort(
                            (a, b) =>
                                this.compare(
                                    a.player.position,
                                    b.player.position
                                ) ||
                                this.compare(b[propertyName], a[propertyName])
                        );
                    }
                } else {
                    if (this.sortOrder === 1) {
                        // sort descending
                        this.sessionData.sort((a, b) =>
                            this.compare(a[propertyName], b[propertyName])
                        );
                    } else if (this.sortOrder === 2) {
                        // sort ascending
                        this.sessionData.sort((a, b) =>
                            this.compare(b[propertyName], a[propertyName])
                        );
                    }
                }
            }
        },
        compare(a, b) {
            // sort comparison helper
            if (a > b) return +1;
            if (a < b) return -1;
            return 0;
        },
        displayDate(date) {
            if (date) {
                return DateUtils.getOrdinalDate(date);
            } else {
                return "";
            }
        },
        async getSession() {
            console.log("GET SESSION DATA");

            const response = await this.$root.webApiGet(
                `/session?customerId=${this.customerId}&sessionId=${this.sessionId}`
            );

            if (response.status === 200) {
                this.session = response.data.session;
                this.sessionDate = new Date(this.session.date);
                this.sessionType = this.session.type;
                this.sessionTypeColour = this.session.typeColour;
                this.sessionTitle = this.session.title;
            } else {
                errorHandler.error(response, this);
            }
        },
        calcSpeedVsPersonalBest(kpi) {
            // calculate peak speed vs personal best percentage
            const percentOfPersonalBest = kpi[this.columnProperties[19]] === 0 ? 100 :
                (kpi[this.columnProperties[3]] /
                    (kpi[this.columnProperties[19]] === null ? kpi[this.columnProperties[3]] : kpi[this.columnProperties[19]])) *
                100;
            const fraction = percentOfPersonalBest % 1;
            if (parseFloat(fraction.toFixed(1)) === 0) {
                return percentOfPersonalBest.toFixed(0);
            } else {
                return percentOfPersonalBest.toFixed(1);
            }
        },
        toggleGroup() {
            // toggle the group by position property
            this.groupByPosition = !this.groupByPosition;
            //this.sort();
        },
        exportData() {
            if (!this.hasExportData) {
                return;
            }

            const psUnit = getMeasurementUnit(this.PeakSpeed);
            const sdUnit = getMeasurementUnit(this.ShortDistance);
            const ldUnit = getMeasurementUnit(this.LongDistance);
            const acUnit = getMeasurementUnit(this.Acceleration);

            const shortDistanceUnit = sdUnit.unitShort;
            const peakSpeedUnit = psUnit.unitShort;
            const accelerationUnit = acUnit.unitShortPlain;

            let csvData = "";

            const accelsDecelsText = this.accelsDecelsDisplay === 'none' ? "" :
                `${acUnit.formatCoarse(3)}${accelerationUnit} Accelerations,` +
                `${acUnit.formatCoarse(3)}${accelerationUnit} Decelerations`;
            
            const includeBBZones = UserData.displayBBSpeedZones();

            const csvHeader =
                `Name, Session Title, Session Date, Session Time (min), Active Time (min),` +
                `Distance (${ldUnit.unitShort}),Peak Speed (${peakSpeedUnit}),% of Max Peak Speed,` +
                `${sdUnit.unitLong} Per Min,` +
                (includeBBZones ? 
                    `Zone2 (${shortDistanceUnit}), ` + 
                    `Zone3 (${shortDistanceUnit}), ` +
                    `Zone4 (${shortDistanceUnit}), ` +
                    `Zone5 (${shortDistanceUnit}), ` +
                    `Zone6 (${shortDistanceUnit}),`
                    :
                    ``    
                ) +
                `High Speed Distance (${shortDistanceUnit}),` +
                `Zone 5 Distance (${shortDistanceUnit}),Sprint Distance (${shortDistanceUnit}),` +
                `High Power Distance (${shortDistanceUnit}),Explosive Distance (${shortDistanceUnit}),` +
                `Low Decelerations (nr of events),Med Decelerations (nr of events),High Decelerations (nr of events),` +
                `Low Accelerations (nr of events),Med Accelerations (nr of events),High Accelerations (nr of events),` +
                `Low Acceleration Distance (${shortDistanceUnit}),Med Acceleration Distance (${shortDistanceUnit}),` +
                `High Acceleration Distance (${shortDistanceUnit}), ${accelsDecelsText} \r\n`;

            csvData += csvHeader;

            this.sessionData.forEach(kpi => {

                const percentOfPersonalBest =
                    (kpi[this.columnProperties[3]] /
                        kpi[this.columnProperties[19]]) *
                    100;

                const accelerations = kpi.accelerations === null ? "" : kpi.accelerations.toString();
                const decelerations = kpi.accelerations === null ? "" : kpi.decelerations.toString();

                const csvLine =
                    kpi.name +
                    "," +
                    this.sessionTitle.replace(/,/g, ';') +
                    "," +
                    this.displayDate(this.sessionDate) +
                    "," +
                    kpi.sessionTotalTimeM.toFixed(1).toString() +
                    "," +
                    kpi.activeTimeM.toFixed(1).toString() +
                    "," +
                    ldUnit.formatFine(kpi.activeDistanceM) +
                    "," +
                    psUnit.formatStandard(kpi.peakSpeedMPerS) +
                    "," +
                    percentOfPersonalBest.toFixed(1) +
                    "," +
                    sdUnit.formatFine(kpi.averageSpeedMPerMinute) +
                    "," +
                    (includeBBZones ?
                        (kpi.bbZone2DistanceM === null ? "" : sdUnit.formatStandard(kpi.bbZone2DistanceM)) + "," +
                        (kpi.bbZone3DistanceM === null ? "" : sdUnit.formatStandard(kpi.bbZone3DistanceM)) + "," +
                        (kpi.bbZone4DistanceM === null ? "" : sdUnit.formatStandard(kpi.bbZone4DistanceM)) + "," +
                        (kpi.bbZone5DistanceM === null ? "" : sdUnit.formatStandard(kpi.bbZone5DistanceM)) + "," +
                        (kpi.bbZone6DistanceM === null ? "" : sdUnit.formatStandard(kpi.bbZone6DistanceM)) + ","
                        :
                        ""
                    ) +
                    sdUnit.formatFine(kpi.highSpeedDistanceM) +
                    "," +
                    sdUnit.formatFine(kpi.zone5DistanceM) +
                    "," +
                    sdUnit.formatFine(kpi.sprintDistanceM) +
                    "," +
                    sdUnit.formatFine(kpi.highPowerDistanceM) +
                    "," +
                    sdUnit.formatFine(kpi.explosiveDistanceM) +
                    "," +
                    kpi.lowDecelerations.toString() +
                    "," +
                    kpi.mediumDecelerations.toString() +
                    "," +
                    kpi.highDecelerations.toString() +
                    "," +
                    kpi.lowAccelerations.toString() +
                    "," +
                    kpi.mediumAccelerations.toString() +
                    "," +
                    kpi.highAccelerations.toString() +
                    "," +
                    sdUnit.formatFine(kpi.lowAccelerationDistanceM) +
                    "," +
                    sdUnit.formatFine(kpi.mediumAccelerationDistanceM) +
                    "," +
                    sdUnit.formatFine(kpi.highAccelerationDistanceM) +
                    "," +
                    accelerations +
                    "," +
                    decelerations +
                    "\r\n";
                
                csvData += csvLine;
            });

            const blob = new Blob([csvData], { type: "text/plain" });
            const file = `session_${this.sessionId.toString()}.csv`;
            download(blob, file);
        },
    },
    async mounted() {
        await this.$root.executeTaskWithProgressBar(async () => {
            console.log("Session.vue mounted");
            this.$root.newPageView("Session Page", UserData.userName());
            await this.refreshData();
            UpdateSetting();
        });
        this.displayUnidentifiedPlayersModal();
    }
};
</script>

<style>
.button-export:disabled {
    opacity:0.4;
    pointer-events: none;
}
.button-export{
    opacity: 0.8;
}
.button-export:hover{
    opacity: 1;
}

</style>