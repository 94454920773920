<template>
    <div id="content-wrap" style="margin-left:90px" :style="{ opacity: !$root.isLoadingData ? 1 : 0 }">
        <MissingDataText v-if="!hasPlayers" :message="'Selected squad is empty'" />
        <div v-else>
            <Header :updateSelection="getSelectedPlayerRehabDates"
                :additionalTitle="hasData ? sessionDateString : ''" />
            <MissingDataText message='No rehab session available' v-if="!hasData" />
            <div v-else>
                <div class="d-flex justify-content-center align-items-center">
                    <div class="text-center">
                        <span>Select session: </span>
                        <b-dropdown variant="outline-primary" :text="sessionsList[selectedIdx].sessionDate">
                            <b-dropdown-item v-for="(option, index) in sessionsList" v-bind:key="index"
                                @click="updateSession(index)" class="pt-2">
                                {{ option.sessionDate }}
                            </b-dropdown-item>
                        </b-dropdown>
                    </div>
                </div>
                <div class="row mx-auto" style="width: 90vw;">
                    <div class="col-12 w-100">
                        <RehabKpiTable :sessionPercentages="sessionPercentages" :sessionValues="sessionValues" />
                    </div>
                    <div class="col-12 w-100">
                        <RehabTurnRate :data="turnClassCounts" :turnEntrySpeedBoundaries="turnEntrySpeedBoundaries" />
                    </div>
                    <div class="col-12 w-100">
                        <RehabPeakDemands :data="peakDemandsData" />
                    </div>
                    <div class="col-12 w-100">
                        <RehabPeakThresholdCount :data="peakThresholdCounts" />
                    </div>
                    <div class="col-12 w-100">
                        <RehabAccelsDecelsCapacity :data="accelDecelCapacity" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Header from "./Header";
import { UserData } from "@/components/UserData";
import { errorHandler } from "@/components/ErrorHandler";
import MissingDataText from "@/components/MissingDataText";
import UpdateSetting from "@/utils/UpdateSetting";
import RehabKpiTable from "./RehabKpiTable.vue";
import RehabPeakDemands from "./RehabPeakDemands.vue";
import RehabAccelsDecelsCapacity from "./RehabAccelsDecelsCapacity.vue";
import RehabPeakThresholdCount from "./RehabPeakThresholdCount.vue";
import RehabTurnRate from "./RehabTurnRate.vue";
import store from './store';

export default {
    components: {
        Header,
        MissingDataText,
        RehabKpiTable,
        RehabPeakDemands,
        RehabAccelsDecelsCapacity,
        RehabPeakThresholdCount,
        RehabTurnRate
    },
    data() {
        return {
            customerId: UserData.customerId(),
            sessionDateString: null,
            sessionPercentages: null,
            sessionValues: null,
            hasData: false,
            hasPlayers: false,
            playerId: null,
            peakDemandsData: {},
            accelDecelCapacity: null,
            peakThresholdCounts: null,
            turnClassCounts: null,
            selectedIdx: 0,
            sessionSelected: null,
            sessionsList: null,
            turnEntrySpeedBoundaries: UserData.turnEntrySpeedBoundaries
        };
    },
    methods: {
        async getSessionOverview() {
            this.playerId = store.state.selectedPlayer === null ? this.playerId : store.state.selectedPlayer.playerId;
            const response = await this.$root.webApiGet(
                `/sessionoverview?customerId=${this.customerId}&playerId=${this.playerId}&sessionId=${this.sessionSelected.sessionId}`);

            if (response.status === 200) {
                const { sessionKpi, rainbowCurvesData, accelDecelCapacity, peakThresholdCounts, turnClassCounts, turnEntrySpeedBoundaries } = response.data;
                const { sessionDate, sessionPercentages, sessionValues } = sessionKpi;
                this.sessionDateString = sessionDate;
                this.sessionPercentages = sessionPercentages;
                this.sessionValues = sessionValues;
                this.peakDemandsData = rainbowCurvesData;
                this.accelDecelCapacity = accelDecelCapacity;
                this.peakThresholdCounts = peakThresholdCounts;
                this.turnClassCounts = turnClassCounts;
                this.turnEntrySpeedBoundaries = turnEntrySpeedBoundaries;
                this.hasData = true;
            } else {
                errorHandler.error(response, this);
            }

        },
        resetData() {
            this.sessionDateString = null;
            this.sessionPercentages = null;
            this.sessionValues = null;
            this.peakDemandsData = {};
            this.accelDecelCapacity = null;
            this.peakThresholdCounts = null;
            this.turnClassCounts = null;
            this.sessionsList = null;
            this.selectedIdx = 0;
            this.sessionSelected = null;
            this.hasData = false;
        },
        async getSelectedPlayerRehabDates() {
            await this.$root.executeTaskWithProgressBar(async () => {
                if (this.playersRehabSessions) {
                    const playerRehabSessions = this.playersRehabSessions[this.selectedPlayer.playerId];
                    if (playerRehabSessions) {
                        this.sessionsList = playerRehabSessions;
                        await this.updateSession(0); //gets most recent session
                    } else {
                        this.resetData();
                    }
                }
            });
        },
        async updateSession(sessionIdx) {
            this.selectedIdx = sessionIdx;
            this.sessionSelected = this.sessionsList[sessionIdx];
            await this.getSessionOverview();
        }
    },
    computed: {
        selectedPlayerIdx() {
            return store.state.selectedPlayerIdx;
        },
        selectedPlayer() {
            return store.state.selectedPlayer;
        },
        playersRehabSessions() {
            return store.state.rehabSessions;
        }
    },
    async mounted() {
        await this.$root.executeTaskWithProgressBar(async () => {
            console.log('SessionOverview.vue mounted');
            this.$root.newPageView("Rehab Session Overview Page", UserData.userName());
            await store.dispatch('getRehabSessions', this);
            await store.dispatch('getPlayers', this);
            this.hasPlayers = store.state.players.length > 0;
            if (this.hasPlayers) {
                await this.getSelectedPlayerRehabDates();
            }
            UpdateSetting();
        });
    },
};
</script>