import { format } from 'date-fns';
import { GeneratedKeys } from "./ReportingKpiThresholdKeyGenerator";
class DataEntryBuilder {
    constructor(data, dataType, matchType) {
        this.data = data;
        this.dataType = dataType;
        this.matchType = matchType;
        this.currentItem = null;
    }

    setItem(item) {
        this.currentItem = item;
    }

    createValueAndPercentileObject(value, percentile) {
        const formattedValue = value === null ? "-" : value;
        const formattedPercentile = percentile === null ? "-" : Math.round(percentile);
        return {
            value: this.dataType === "data" ? formattedValue : formattedPercentile,
            percentile: formattedPercentile
        };
    }

    retrieveKpi(kpiType, threshold) {
        // WR is time based and other kpis are distance based
        const thresholdAndPercentileKeys = kpiType === 'WORK_RATE' ? ['highResolutionTimeByThreshold', 'highResolutionTimePercentileByThreshold'] :
            ['highResolutionDistanceByThreshold', 'highResolutionDistancePercentileByThreshold'];
        // Retrieve the KPI value/percentile if it exists or set it to null if undefined or missing
        // Use nullish coalescing (??) to ensure 0 is treated as a valid value and not replaced with null
        const rawValue = this.currentItem.reportingKpis[thresholdAndPercentileKeys[0]]?.[this.matchType]?.[kpiType]?.[threshold] ?? null;
        //Added a space just before the work rate time formatting so we preserve format in Excel
        const value = (kpiType === "WORK_RATE" && rawValue != null) ? ` ${Math.floor(rawValue / 60)}:${format(new Date((rawValue % 60) * 1000), "ss")}` : rawValue;
        const percentile = this.currentItem.reportingKpis[thresholdAndPercentileKeys[1]]?.[this.matchType]?.[kpiType]?.[threshold] ?? null;
        return this.createValueAndPercentileObject(value, percentile);
    }

    buildDataEntry() {
        const {
            player,
            sessionInfo,
            reportingKpis
        } = this.currentItem;

        const {
            sportlightMatchTimeM,
            wellnessKpis,
            totalDistanceM,
            totalDistanceMPercentile,
            scaledWorkRate,
            scaledWorkRatePercentile,
        } = reportingKpis;
        
        const tableItem = {
            matchTime: sportlightMatchTimeM,
            fatigueFlag: wellnessKpis?.fatigueFlagValue,
            hamstringRisk: {
                hamstringRisk: wellnessKpis?.hamstringRisk,
                hamstringRiskScore: wellnessKpis?.hamstringRiskScore
            },
            totalDistance: this.createValueAndPercentileObject(totalDistanceM, totalDistanceMPercentile),
            workRate: this.createValueAndPercentileObject(scaledWorkRate, scaledWorkRatePercentile),
        };
        Object.entries(GeneratedKeys).forEach(([metricType, keys]) => {
			Object.entries(keys).forEach(([threshold, keyName]) => {
				tableItem[keyName] = this.retrieveKpi(metricType, threshold);
			});
		});
        
        if (player) {
            tableItem.playerId = player.playerId;
            tableItem.shirtNumber = player.shirtNumber;
            tableItem.name = player.name;
            tableItem.position = player.positionShortDescription;
            tableItem.positionId = player.positionSortOrder;
        } else {
            tableItem.sessionId = sessionInfo.sessionId;
            tableItem.name = sessionInfo.oppositionName;
            tableItem.sessionDate = sessionInfo.date;
            tableItem.title = sessionInfo.title;
        }

        return tableItem;
    }
}

export default class ReportingTableDataGenerator {
	static createTableData(data, dataType, matchType) {
		const builder = new DataEntryBuilder(data, dataType, matchType);
		const tableData = data.map((item) => {
			builder.setItem(item);
			return builder.buildDataEntry();
		});

		tableData.sort((a, b) => {
			if (a.playerId && b.playerId) {
				return a.positionId === b.positionId
					? a.shirtNumber - b.shirtNumber
					: a.positionId - b.positionId;
			} else if (a.sessionDate && b.sessionDate) {
				return new Date(a.sessionDate) - new Date(b.sessionDate);
			}
			return 0;
		});

		return tableData;
	}
}