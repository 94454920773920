<template>
    <button class="export-button" :class="[{ disabled: isDisabled }, 'button', 'button-export', 'float-right']"
        style="color:var(--sportlight-teal);" @click="handleClick" :aria-disabled="isDisabled" :disabled="isDisabled">
        <span class="button-text">
            <svg class="download" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
                <path class="cls-1"
                    d="M7.76,12.57a.33.33,0,0,0,.47,0L12.9,7.9a.33.33,0,0,0-.24-.57h-2v-7A.33.33,0,0,0,10.33,0H5.67a.33.33,0,0,0-.33.33v7h-2a.33.33,0,0,0-.24.57Z" />
                <path class="cls-1"
                    d="M15.33,11.67a.67.67,0,0,0-.67.67v2.33H1.33V12.33a.67.67,0,1,0-1.33,0v3A.67.67,0,0,0,.67,16H15.33a.67.67,0,0,0,.67-.67v-3A.67.67,0,0,0,15.33,11.67Z" />
            </svg>
            Export
        </span>
    </button>
</template>

<script>
export default {
    props: {
        onClick: Function,
        isDisabled: Boolean
    },
    methods: {
        handleClick() {
            this.onClick();
        }
    },
};
</script>