<template>
    <div class="section-container w-100">
        <SectionTitle :title="'Acceleration/Deceleration Capacity'"> 
            <MoreInfo :id="'accels-decels-capacity-more-info'" title='Accelerations/Decelerations Capacity'>
                <p>
                    Percentage of match average acceleration/deceleration capacity reached, by speed bins.
                </p>
            </MoreInfo>
        </SectionTitle>
        <MissingDataText message='Acceleration/Deceleration capacity unavailable' v-if="!hasData"/>
        <div class="overflow-auto" v-else>
            <table>
                <tr style="border-bottom: 1px solid grey;">
                    <td class="label-cell">Acceleration</td>
                    <td v-for="(k, index) in accelerations" :key="index">
                        <div class="battery" :style="{ backgroundColor: k.colour }">
                            <span>{{ k.value + '%' }}</span>
                        </div>
                    </td>
                </tr>
                <tr style="border-bottom: 1px solid white;">
                    <td class="label-cell">Deceleration</td>
                    <td v-for="(k, index) in decelerations" :key="index">
                        <div class="battery" :style="{ backgroundColor: k.colour }">
                            <span>{{ k.value + '%' }}</span>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td class="label-cell">Speed Bins (<MeasurementUnit :type="Speed" />) </td>
                    <td v-for="(k, index) in speedBins" :key="index">
                        <div style="border-top: 1vh solid white; width: 2px; margin: auto;"></div>
                        <div class="battery">
                            <span>{{ k }}</span>
                        </div>
                    </td>
                </tr>
            </table>
        </div>
    </div>
</template>

<script>
import SectionTitle from "@/components/SectionTitle.vue";
import MissingDataText from "@/components/MissingDataText.vue";
import MeasurementUnit from "@/components/MeasurementUnit.vue";
import MoreInfo from "@/components/MoreInfo.vue";
import { MeasurementTypes, getMeasurementUnit } from "@/utils/MeasurementSystem";

export default {
    props: {
        data: Object
    },
    components: {
        SectionTitle,
        MissingDataText,
        MeasurementUnit,
        MoreInfo
    },
    data() {
        return {
            Speed: MeasurementTypes.Speed,
            speedUnit: getMeasurementUnit(MeasurementTypes.Speed),
            hasData: false,
            accelerations: null,
            decelerations: null,
            speedBins: null
        };
    },
    methods: {
        async getAccelsDecelsCapacity() {
            this.hasData = this.data !== null;
            if (this.hasData) {

                const calculateOpacity = value => {
                    if (isNaN(value)) { return 1; }
                    const opacityValue = (((value * 0.8) + 10) * 0.01).toFixed(2);
                    return opacityValue;
                };

                const createAccelsDecelsBlocks = values => values.map((value, i) => ({
                    id: i,
                    colour: `rgba(26, 213, 237, ${calculateOpacity(value)})`,
                    value
                }));

                const accels = [];
                const decels = [];
                const speeds = [];

                for (let startRange = 3; startRange < 10; startRange += 0.5) {
                    const endRange = startRange + 0.5;
                    const range = `${startRange}-${endRange}`;
                    accels.push(this.data.accelPercentageLabels[range] || 0); //return 0s for ranges without values
                    decels.push(this.data.decelPercentageLabels[range] || 0);
                    speeds.push(`${this.speedUnit.formatStandard(startRange) }-${ this.speedUnit.formatStandard(endRange)}`);
                }

                this.decelerations = createAccelsDecelsBlocks(decels);
                this.accelerations = createAccelsDecelsBlocks(accels);
                this.speedBins = speeds;
                
            }
        },
    },
    async mounted() {
        await this.getAccelsDecelsCapacity();
    },
    watch: {
        async data() {
            await this.getAccelsDecelsCapacity();
        }
    }
};
</script>

<style scoped>
table {
    margin: auto;  
    white-space: nowrap;
}
td {
    padding: 0.1em 0.2em;
}
.label-cell {
    border-right: 1px solid grey;
    padding-right: 1vw;
}
.battery {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto;
    min-width: 5vw;
    height: 5vh;
}
</style>